/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState, useRef } from 'react'
import {
  ProjectContext,
  TargetContext,
  highlightsContext,
} from '../../../context/globalContext'
import { ReactComponent as MagniferIcon } from '../../../assets/images/magnifer.svg'
import { ReactComponent as PlusIcon } from '../../../assets/images/add-dark.svg'
import { ReactComponent as FilledPlusIcon } from '../../../assets/images/filled_add.svg'
import { ReactComponent as ThumbUpIcon } from '../../../assets/images/Like.svg'
import { ReactComponent as FilledThumbUpIcon } from '../../../assets/images/FilledLike.svg'
import { ReactComponent as ThumbDownIcon } from '../../../assets/images/Dislike.svg'
import { ReactComponent as FilledThumbDownIcon } from '../../../assets/images/FilledDislike.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg'
import editIcon from '../../../assets/images/Edit.svg'
import filledEditIcon from '../../../assets/images/stop_edit.svg'
import upCircleIcon from '../../../assets/images/roundArrowUp.svg'
import downCircleIcon from '../../../assets/images/roundArrowDown.svg'
import infoCircleIcon, {
  ReactComponent as InfoIcon,
} from '../../../assets/images/info_circle.svg'

import { Panel, PanelGroup } from 'react-resizable-panels'
import './collations.scss'
import {
  acknowledgeCollation,
  createManualCollation,
  deleteCollation,
  feedbackCollation,
  getCollation,
  getSourcePhraseByPhraseId,
  updateCollation,
} from '../../../services/collation-service'
import { enqueueSnackbar } from 'notistack'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import LightTooltip from '../../../components/tooltip/tooltip'
import CancelActionConformationFormDialog from '../../../components/form-dialog/cancel-conformation'
import { ReactComponent as ActiveInfoIcon } from '../../../assets/images/active_info_circle.svg'
import { ReactComponent as FilledInfoIcon } from '../../../assets/images/info_filled.svg'
import { useTranslation } from 'react-i18next'
import { getLocalizedPath } from '../../../utils/shareLocalizePath'
import TextButton from '../../../components/button/textButton'
import { ReactComponent as CircleFilledCheckIcon } from '../../../assets/images/circle_filled_check.svg'
import axios from 'axios'

interface Props {
  setCurrentPage
  handleGetTargetFiles
  isTargetDataLoading
  selectedResultId
  setSelectedResultId
}

export const Collations = ({
  setCurrentPage,
  handleGetTargetFiles,
  isTargetDataLoading,
  selectedResultId,
  setSelectedResultId,
}: Props) => {
  const {
    collationList,
    setCollationList,
    darkColor,
    isCollationEditMode,
    setIsCollationEditMode,
    selectedHighlight,
    manualCollation,
    setManualCollation,
    phraseId,
    isCollationDataLoading,
    setIsCollationDataLoading,
    setShowTargetPdfAndCollation,
    isManualCollationInProgress,
    setIsManualCollationInProgress,
    setSelectedCollation,
    selectedCollation,
    isCollationUpdating,
    setIsCollationUpdating,
    showTargetPdfAndCollation,
    handleProjectStatus,
    setSourcePhrases,
    areaList,
    setAreaList,
  } = useContext(highlightsContext)
  const [isCollationExpanded, setIsCollationExpanded] = useState<string>('')

  const [collationQuery, setCollationQuery] = useState('')

  const [resultAreaLoading, setResultAreaLoading] = useState(false)
  const { projectId } = useContext(ProjectContext)
  const { targetId } = useContext(TargetContext)
  const [highlightList, setHighlightList] = React.useState<any[]>([])

  const [activeCollationId, setActiveCollationId] = useState<any>(null)
  const [activeHighlightId, setActiveHighlightId] = useState<any>(null)
  const [newTargetId, setNewTargetId] = useState('')
  const [newPhraseId, setNewPhraseId] = useState('')
  const collationRef = useRef(null)
  const [dialogState, setDialogState] = useState<any>({
    isOpen: false,
    collation: {},
    type: null, // 'delete' or 'update'
  })

  const [queryParameters] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const basePath = getLocalizedPath(currentLanguage, location.pathname)
  const containerRef = useRef<any>(null)
  const refs = useRef({})

  const updateHash = (phrase, targetPhrase, collation) => {
    let newHash = `phrase-${phrase}`
    if (targetPhrase) {
      newHash += ';' + `collation-${targetPhrase}`
    }
    // Update the location hashnavigate
    navigate(
      `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${queryParameters.get('target_id')}&area_id=${queryParameters.get('area_id')}&phrase_id=${phraseId}&collation_id=${collation}`,
      { state: { location }, replace: true },
    )
    document.location.hash = newHash
  }

  const parseIdFromHash = () => {
    const hash = document.location.hash.substr(1)
    const parts = hash.split(';')
    const state = {
      phrase: parts[0].slice('#phrase-'.length) || '',
      collation: parts.length > 1 ? parts[1].slice('collation-'.length) : '',
    }
    return state.collation
  }

  useEffect(() => {
    if (showTargetPdfAndCollation && phraseId) {
      // if (newTargetId !== targetId) {
      //   // setHighlightList([])
      //   setSelectedCollation([])
      //   setIsCollationExpanded('')
      //   setNewTargetId(targetId)
      // }
      // if (newPhraseId !== phraseId) {
      //   // setHighlightList([])
      //   setSelectedCollation([])
      //   setIsCollationExpanded('')
      //   setNewPhraseId(phraseId)
      // }
      setSelectedResultId(null)
      setIsCollationExpanded(
        `collationPanel${queryParameters.get('collation_id')}`,
      )
      setIsCollationDataLoading(true)
      getCollation(projectId, queryParameters.get('phrase_id'), targetId)
        .then((res) => {
          if (res.status === 200) {
            setIsCollationDataLoading(false)
            const highlightId = parseIdFromHash()
            const collationIdFromUrl = queryParameters.get('collation_id')

            const updatedData = res.data.data.map((highlight) => {
              const collationResultsWithColour =
                highlight.collation_results.map((result) => {
                  const color = darkColor(result.verification.status)
                  return {
                    ...result,
                    colour: color,
                  }
                })

              // Update the highlight list if collation ID matches
              if (queryParameters.get('phrase_id') && collationIdFromUrl) {
                if (+highlight.id === +collationIdFromUrl) {
                  setHighlightList(collationResultsWithColour)
                }
              }

              return {
                ...highlight,
                collation_results: collationResultsWithColour,
              }
            })

            // If specific conditions are met, update selected collation
            updatedData.forEach((highlight) => {
              highlight.collation_results.forEach((result) => {
                if (+highlightId === +result.id) {
                  setSelectedCollation([{ ...result, colour: result.colour }])
                  setCurrentPage(result.page)
                  setSelectedResultId(+highlightId)
                }
              })
            })

            setCollationList(updatedData)
          }
        })
        .catch((error) => {
          // To handle cancel axios error
          if (axios.isCancel(error)) {
            console.debug('Request canceled:', error)
          } else if (
            error?.response?.data?.detail &&
            error?.response?.request?.status !== 500 &&
            error?.response?.request?.status !== 502
          ) {
            setIsCollationDataLoading(false)
            enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
              variant: 'error',
              persist: true,
            })
          }
          if (error?.response?.request?.status === 502) {
            setIsCollationDataLoading(false)
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
    }
  }, [targetId, phraseId])
  useEffect(() => {
    if (!isCollationEditMode.collation) {
      const collationId = queryParameters.get('collation_id')
      if (refs.current[`collation${collationId}`] && containerRef.current) {
        const container = containerRef.current
        const element = refs.current[`collation${collationId}`]
        const containerRect = container.getBoundingClientRect()
        const elementRect = element.getBoundingClientRect()

        if (
          elementRect.top < containerRect.top ||
          elementRect.bottom > containerRect.bottom
        ) {
          container.scrollTop += elementRect.top - containerRect.top - 10
        }
      }
    }
  }, [collationList])

  useEffect(() => {
    if (isCollationEditMode.isEditMode && collationList?.length > 0) {
      // Map through collationList to update each highlight's results with colour
      const updatedCollationList = collationList.map((highlight) => {
        // Determine if this is the highlight being edited
        if (highlight.id === isCollationEditMode.collation?.id) {
          setHighlightList(highlight.collation_results) // Set the collation results of the highlighted item
          setIsCollationExpanded(
            `collationPanel${highlight.collation_results[0].id}`,
          )
          const result = highlight.collation_results[0]
          const color = darkColor(result.verification.status)
          setSelectedCollation([{ ...result, colour: color }])
          setCurrentPage(result.page)
          setSelectedResultId(+result.id)
          updateHash(phraseId, result.id, highlight.id)
        }

        // Map through each result to add colour
        const updatedResults = highlight.collation_results.map((result) => {
          const color = darkColor(result.verification.status)
          return {
            ...result,
            colour: color,
          }
        })

        return {
          ...highlight,
          collation_results: updatedResults,
        }
      })

      // Optionally, if you need to update the collationList state with these changes, do it here
      setCollationList(updatedCollationList)

      // Reset editing mode state after processing
      setIsCollationUpdating(false)
      setIsCollationEditMode({
        isEditMode: false,
        phraseId: null,
        collation: isCollationEditMode.collation?.id,
      })
    }
  }, [collationList])

  // To update highlight color locally
  const colorHighlight = ({
    highlight,
    resFeedback,
    isActionClick,
    from,
  }: any) => {
    const updatedHighlights = highlightList?.map((h: any) => {
      if (+h?.id === +highlight?.id) {
        let newApprovalStatus
        if (isActionClick) {
          if (resFeedback === true) {
            newApprovalStatus = true // Assuming 'approved' is the correct status value
          } else if (resFeedback === false) {
            newApprovalStatus = false // Assuming 'rejected' is the correct status value
          } else {
            newApprovalStatus = null // Adjust as needed for your default or null state
          }
        } else {
          newApprovalStatus = resFeedback // Make sure this aligns with how resFeedback is structured
        }

        const color = darkColor(newApprovalStatus)
        setSelectedCollation([
          {
            ...highlight,
            colour: color,
            verification: { ...h.verification, status: newApprovalStatus },
          },
        ])
        setCurrentPage(highlight.page)
        return {
          ...h,
          colour: color,
          is_seleted: true, // Note: Typically, it's spelled "is_selected"
          verification: { ...h.verification, status: newApprovalStatus },
        }
      } else {
        const color = darkColor(h.verification.status)
        return {
          ...h,
          colour: color,
          is_seleted: false, // Note: Typically, it's spelled "is_selected"
        }
      }
    })
    setHighlightList(updatedHighlights)
  }

  // On click like button
  const handleLike = (
    highlight,
    projectId,
    phraseId,
    highlightId,
    collationId,
  ) => {
    if (highlight.verification?.status === true) {
      handleFeedback(
        highlight,
        projectId,
        phraseId,
        highlightId,
        collationId,
        null,
      ) // 'like' is active
    } else {
      handleFeedback(
        highlight,
        projectId,
        phraseId,
        highlightId,
        collationId,
        true,
      ) // 'like' is inactive
    }
  }

  // On click dislike button
  const handleDislike = (
    highlight,
    projectId,
    phraseId,
    highlightId,
    collationId,
  ) => {
    if (highlight.verification?.status === false) {
      handleFeedback(
        highlight,
        projectId,
        phraseId,
        highlightId,
        collationId,
        null,
      ) // 'dislike' is active
    } else {
      handleFeedback(
        highlight,
        projectId,
        phraseId,
        highlightId,
        collationId,
        false,
      ) // 'dislike' is inactive
    }
  }

  // Function to handle both 'like' and 'dislike' button clicks
  const handleFeedback = (
    highlight,
    projectId,
    phraseId,
    highlightId,
    collationId,
    feedbackValue,
  ) => {
    setResultAreaLoading(true)
    const feedbackData = { verification: feedbackValue }

    // API call to update feedback
    feedbackCollation(+projectId, phraseId, collationId, feedbackData)
      .then((res) => {
        if (res.status === 200) {
          if (queryParameters.get('area_id')) {
            const updatedAreaList = areaList.map((area) => {
              if (
                Number(area.id) === Number(queryParameters.get('area_id')) &&
                area.collations_verified
              ) {
                return {
                  ...area,
                  collations_verified: false,
                  verified_badge: false,
                }
              } else return area
            })
            setAreaList(updatedAreaList)
          }
          handleGetPhraseByPhraseId(phraseId)
          handleProjectStatus(projectId)
          // Update the specific collation's status locally
          const updatedCollations = collationList?.map((item) => {
            if (item.id === highlightId) {
              const updatedResults = item?.collation_results?.map((result) => {
                if (result.id === collationId) {
                  const color = darkColor(feedbackValue)
                  return {
                    ...result,
                    colour: color,
                    verification: { status: feedbackValue },
                  }
                }
                return result
              })

              return { ...item, collation_results: updatedResults }
            }
            return item
          })
          setCollationList(updatedCollations)
          // Update highlight color for visual feedback
          if (highlight && feedbackValue !== undefined) {
            const resFeedback = feedbackValue
            const selectedColor = darkColor(resFeedback)
            colorHighlight({
              highlight,
              resFeedback,
              isActionClick: true,
            })
          }

          setResultAreaLoading(false)
        }
      })
      .catch((error) => {
        setResultAreaLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleGetCollation = (phraseId) => {
    // getCollation(1, 1, 9)
    setIsCollationDataLoading(true)
    getCollation(projectId, phraseId, targetId)
      .then((res) => {
        if (res.status === 200) {
          setIsCollationDataLoading(false)
          // Use map instead of forEach to create a new array with updated results
          const updatedData = res.data.data.map((highlight) => {
            // Check if this is the highlight being edited and update highlight list
            if (highlight?.id === isCollationEditMode?.collation?.id) {
              const updatedResults = highlight.collation_results.map(
                (result) => {
                  const color = darkColor(result.verification.status)
                  return { ...result, colour: color }
                },
              )
              setHighlightList(updatedResults) // Update state with modified results
              return { ...highlight, collation_results: updatedResults }
            }

            // Always return highlight, optionally with modified results
            return {
              ...highlight,
              collation_results: highlight.collation_results.map((result) => {
                const color = darkColor(result.verification.status)
                return { ...result, colour: color }
              }),
            }
          })
          setCollationList(updatedData) // Update the collation list state
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          setIsCollationDataLoading(false)
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          setIsCollationDataLoading(false)
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleAddManualCollation = (text: any) => {
    const phraseFormData = {
      text,
    }
    setIsManualCollationInProgress(true)
    createManualCollation(projectId, phraseFormData, phraseId)
      .then((res) => {
        if (res.status === 200 && phraseId) {
          handleProjectStatus(projectId)
          setIsCollationDataLoading(false)
          setIsManualCollationInProgress(false)
          handleGetCollation(phraseId)
          setCollationQuery('')
          setHighlightList([])
          setSelectedCollation([])
          setIsCollationExpanded('')
          handleGetTargetFiles(projectId, '', phraseId)
        }
      })
      .catch((error) => {
        setIsManualCollationInProgress(false)
        setCollationQuery('')
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleDeleteCollation = (collationId) => {
    setIsCollationDataLoading(true)
    deleteCollation(projectId, phraseId, collationId)
      .then((res) => {
        if (res.status === 204 && phraseId) {
          handleProjectStatus(projectId)
          setIsCollationDataLoading(false)
          const hashParts = document.location.hash.split(';') // Split the hash by ';'
          document.location.hash = hashParts[0] + ';'
          setHighlightList([])
          setSelectedCollation([])
          handleGetCollation(phraseId)
          handleGetTargetFiles(projectId, '', phraseId)
          setIsCollationExpanded('')
          handleGetPhraseByPhraseId(phraseId)
        }
      })
      .catch((error) => {
        setCollationQuery('')
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleGetPhraseByPhraseId = (id) => {
    getSourcePhraseByPhraseId(projectId, id)
      .then((res: any) => {
        setSourcePhrases((prev) => {
          // Assuming res contains the updated item data and has an 'id' property
          const updatedItemId = res.data.id
          // Create a new array with the updated item
          const updatedPhrases = prev.map((item) =>
            item.id === updatedItemId ? { ...item, ...res.data } : item,
          )
          // Return the updated array as the new state
          return updatedPhrases
        })
      })
      .catch((error) => {
        setCollationQuery('')
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  // Opens a "Backgrowd prcrss again message "
  const handleOpenReplaceConfirmDialog = (from, collation) => {
    setDialogState({
      isOpen: true,
      collation,
      type: from,
    })

    setActiveCollationId(collation)
  }

  const handlCloseCancelDeleteConfirmDialog = (result: string) => {
    if (result === 'confirm') {
      if (dialogState?.collation && dialogState?.isOpen && dialogState?.type) {
        if (dialogState.type === 'delete') {
          handleDeleteCollation(dialogState?.collation?.id)
        } else if (dialogState.type === 'update') {
          setHighlightList([])
          // setSelectedCollation([])
          // const hashParts = document.location.hash.split(';') // Split the hash by ';'
          // document.location.hash = hashParts[0] + ';'
          // setIsCollationExpanded('')
        }
      }
    } else {
      setIsCollationEditMode({
        isEditMode: false,
        phraseId: null,
        collation: null,
      })
    }
    setDialogState({ isOpen: false, collation: {}, type: null }) // Reset dialog state
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <Stack
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      direction={'column'}
      width={'100%'}
      height={'100%'}
    >
      <Box width={'100%'} height={'100vh'}>
        <Box px={'1rem'} display="flex" justifyContent={'space-between'}></Box>
        <PanelGroup direction="vertical" ref={collationRef}>
          <Panel minSize={15} order={1}>
            <Box height={'100%'}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                gap={1}
                px={'10px'}
                mt={'5px'}
              >
                <TextField
                  id="collation"
                  placeholder={t('Manual Collation')}
                  variant="outlined"
                  size="small"
                  disabled={isManualCollationInProgress || !collationList}
                  style={{
                    flex: 1,
                  }}
                  value={collationQuery}
                  onChange={(e) => {
                    setCollationQuery(e.target.value)
                  }}
                  sx={{
                    flex: 1,
                    '& .MuiInputBase-input': {
                      color: 'black', // Change text color
                    },
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleAddManualCollation(collationQuery)
                    }
                  }}
                  InputProps={{
                    endAdornment: !isManualCollationInProgress ? (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: 'pointer',
                          opacity: !collationList ? '0.8' : 1,
                        }}
                        onClick={() => {
                          handleAddManualCollation(collationQuery)
                        }}
                        disablePointerEvents={!collationList}
                      >
                        <MagniferIcon />
                      </InputAdornment>
                    ) : (
                      <CircularProgress size={20} />
                    ),
                    style: {
                      borderRadius: '4px',
                    },
                  }}
                />
                {manualCollation.isEditMode ? (
                  <IconButton
                    style={{
                      border: '1px solid var(--primary-600)',
                      borderRadius: '4px',
                    }}
                    onClick={() => {
                      setManualCollation({
                        isEditMode: false,
                        phraseId: null,
                      })
                    }}
                  >
                    <FilledPlusIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    style={{
                      border: '1px solid var(--gray-200)',
                      borderRadius: '4px',
                      opacity: !collationList ? '0.8' : 1,
                    }}
                    disabled={!collationList}
                    onClick={() => {
                      setManualCollation({
                        isEditMode: true,
                        phraseId,
                      })
                      setIsCollationExpanded('')
                      setHighlightList([])
                      setSelectedCollation([])
                      queryParameters.delete('collation_id')
                      document.location.hash = `phrase-${phraseId}`
                      setIsCollationEditMode({
                        isEditMode: false,
                        phraseId: null,
                        collation: null,
                      })
                    }}
                  >
                    <PlusIcon />
                  </IconButton>
                )}
                <Tooltip title={t('Information')}>
                  {anchorEl ? (
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      aria-controls={open ? 'info-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <FilledInfoIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={handleClick}
                      sx={{
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                      size="small"
                      aria-controls={open ? 'info-mMenuenu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <span className="icon-wrapper">
                        <InfoIcon className="default-icon" />
                        <ActiveInfoIcon className="active-icon" />
                      </span>
                    </IconButton>
                  )}
                </Tooltip>

                <InfoMenu
                  anchorEl={anchorEl}
                  open={open}
                  handleClose={handleClose}
                  t={t}
                />
              </Box>
              <Paper
                className="webkit-fill-available moz-available fill-available"
                style={{
                  height: '100%',
                  width: '100%',
                  padding: '0 10px',
                  marginTop: '10px',
                  overflowY: 'scroll',
                  boxShadow: 'none',
                }}
                ref={containerRef}
              >
                {!isCollationDataLoading ? (
                  collationList && collationList.length > 0 ? (
                    <SearchHighlightsList
                      highlightList={highlightList}
                      setHighlightList={setHighlightList}
                      updateHash={updateHash}
                      handleLike={handleLike}
                      handleDislike={handleDislike}
                      isCollationExpanded={isCollationExpanded}
                      setIsCollationExpanded={setIsCollationExpanded}
                      collationList={collationList}
                      isCollationEditMode={isCollationEditMode}
                      setIsCollationEditMode={setIsCollationEditMode}
                      projectId={projectId}
                      phraseId={phraseId}
                      manualCollation={manualCollation}
                      setManualCollation={setManualCollation}
                      colorHighlight={colorHighlight}
                      darkColor={darkColor}
                      handleAddManualCollation={handleAddManualCollation}
                      isCollationDataLoading={isCollationDataLoading}
                      handleDeleteCollation={handleDeleteCollation}
                      parseIdFromHash={parseIdFromHash}
                      setIsCollationDataLoading={setIsCollationDataLoading}
                      setShowTargetPdfAndCollation={
                        setShowTargetPdfAndCollation
                      }
                      resultAreaLoading={resultAreaLoading}
                      setSelectedCollation={setSelectedCollation}
                      isCollationUpdating={isCollationUpdating}
                      setIsCollationUpdating={setIsCollationUpdating}
                      handleOpenReplaceConfirmDialog={
                        handleOpenReplaceConfirmDialog
                      }
                      selectedCollation={selectedCollation}
                      selectedResultId={selectedResultId}
                      setSelectedResultId={setSelectedResultId}
                      t={t}
                      setCurrentPage={setCurrentPage}
                      containerRef={containerRef}
                      handleGetCollation={handleGetCollation}
                      setActiveCollationId={setActiveCollationId}
                      queryParameters={queryParameters}
                      refs={refs}
                    />
                  ) : (
                    <div style={{ paddingTop: '20rem' }}>
                      <span>
                        {collationList === null
                          ? t('Please select Source phrase')
                          : t('No collations found')}
                      </span>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      paddingTop: '20rem',
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </Paper>
            </Box>
          </Panel>
        </PanelGroup>
      </Box>
      <CancelActionConformationFormDialog
        open={dialogState.isOpen}
        onClose={handlCloseCancelDeleteConfirmDialog}
        from={'collations'}
        type={dialogState.type}
        message={
          dialogState.type === 'delete'
            ? t(
                'Deleting collation will result in permanent loss of collation with their verfication and approved status. Are you sure?',
              )
            : t(
                'Updating collation will result in permanent loss of verfication and approved status of this collation. Are you sure?',
              )
        }
        projectId={projectId}
      ></CancelActionConformationFormDialog>
    </Stack>
  )
}

// eslint-disable-next-line react/prop-types
const SearchHighlightsList = ({
  collationList,
  highlightList,
  setHighlightList,
  updateHash,
  handleLike,
  handleDislike,
  isCollationExpanded,
  setIsCollationExpanded,
  isCollationEditMode,
  setIsCollationEditMode,
  projectId,
  phraseId,
  manualCollation,
  setManualCollation,
  colorHighlight,
  darkColor,
  handleAddManualCollation,
  isCollationDataLoading,
  handleDeleteCollation,
  parseIdFromHash,
  setIsCollationDataLoading,
  setShowTargetPdfAndCollation,
  resultAreaLoading,
  selectedCollation,
  setSelectedCollation,
  isCollationUpdating,
  setIsCollationUpdating,
  handleOpenReplaceConfirmDialog,
  selectedResultId,
  setSelectedResultId,
  t,
  setCurrentPage,
  containerRef,
  handleGetCollation,
  setActiveCollationId,
  queryParameters,
  refs,
}) => {
  const [activePopoverId, setActivePopoverId] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const openKebabMenu = Boolean(anchorEl)

  const id = openKebabMenu ? 'collation-popover' : undefined
  const handleClose = () => {
    setAnchorEl(null)
    setActivePopoverId(null) // Reset active popover ID
  }

  const handleChange =
    (panel: string, highlight) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setIsCollationExpanded(isExpanded ? panel : '')
      if (isExpanded && highlight) {
        if (highlight.collation_results.length > 0) {
          const updatedCollationResults = highlight.collation_results?.map(
            (result) => {
              return {
                ...result,
                colour: darkColor(result.verification.status),
              }
            },
          )
          setHighlightList(updatedCollationResults)
          setSelectedCollation([updatedCollationResults[0]])
          setCurrentPage(updatedCollationResults[0].page)
          setSelectedResultId(updatedCollationResults[0].id)
          updateHash(phraseId, updatedCollationResults[0].id, highlight.id)
        }
      } else {
        setHighlightList([])
      }
    }

  const [anchorElPhrase, setAnchorElPhrase] = React.useState(null)

  const handleClickPhraseInfo = (event: any) => {
    event.stopPropagation()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setAnchorElPhrase(event.currentTarget)
  }

  const handleClosePhraseInfo = () => {
    setAnchorElPhrase(null)
  }
  const openPhraseInfo = Boolean(anchorElPhrase)
  const handleAcknowledge = (collationId) => {
    acknowledgeCollation(projectId, phraseId, collationId).then((res) => {
      if (res.status === 200) {
        setHighlightList([])
        setSelectedCollation([])
        handleGetCollation(phraseId)
      }
    })
  }
  const checkBorderCondition = (highlight) => {
    const isEditMode = isCollationEditMode.isEditMode
    const editModeMismatch =
      isEditMode && isCollationEditMode.collation.id !== highlight.id

    const condition =
      (isCollationExpanded === `collationPanel${highlight.id}` ||
        highlight.collation_results.some(
          (item) => item.id === selectedResultId,
        )) &&
      (editModeMismatch || !isEditMode)
    return condition
  }
  return (
    <Stack>
      <div style={{ marginBottom: '120px' }}>
        {/* Loop through each highlight */}
        {collationList?.map((highlight, index) => {
          const collationContent = (
            <Accordion
              className="collation-accordion"
              style={{
                margin: '10px 0',
                color:
                  highlight.status.code === 'PAGE_CHANGED'
                    ? 'var(--yellow-500)'
                    : highlight.status.code === 'PAGE_NOT_MATCHED'
                      ? 'var(--red-500)'
                      : 'var(#000)',
                borderRadius: '4px',
                border: checkBorderCondition(highlight)
                  ? '1px solid var(--primary-600)'
                  : isCollationEditMode.isEditMode &&
                      isCollationEditMode.collation.id === highlight.id
                    ? '2px solid var(--primary-600)'
                    : highlight.status.code === 'PAGE_CHANGED'
                      ? '1px solid var(--yellow-300)'
                      : highlight.status.code === 'PAGE_NOT_MATCHED'
                        ? '1px solid var(--red-300)'
                        : '1px solid var(--gray-200)',
              }}
              key={highlight.id}
              // Ensure the expanded prop is always controlled by passing a boolean
              expanded={isCollationExpanded === `collationPanel${highlight.id}`}
              onChange={handleChange(
                `collationPanel${highlight.id}`,
                highlight,
              )}
              ref={(el) => (refs.current[`collation${highlight?.id}`] = el)}
            >
              <AccordionSummary
                // expandIcon={<ArrowDownIcon />}
                sx={{
                  pointerEvents: 'none',
                  margin: '0px',
                  '& .Mui-expanded': {
                    margin: '0',
                  },
                  '& .MuiAccordion-summary': {
                    margin: '0',
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: '0',
                  },
                }}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{
                  gap: '10px',
                  minHeight: '120px',
                  height: 'fit-content',
                }}
              >
                <Stack
                  className="webkit-fill-available moz-available fill-available"
                  display={'flex'}
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                  width={'100%'}
                  gap={2}
                  style={{
                    opacity:
                      isCollationEditMode.collation?.id === highlight.id &&
                      isCollationUpdating
                        ? 0.5
                        : 1,
                  }}
                >
                  <LightTooltip
                    title={highlight?.text}
                    placement="top"
                    arrow={true}
                  >
                    <Typography
                      textAlign={'left'}
                      fontWeight={500}
                      maxHeight={'max-content'}
                      mt={'8px'}
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2, // Number of lines to show
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        pointerEvents:
                          (isCollationExpanded ===
                            `collationPanel${highlight.id}` &&
                            resultAreaLoading) ||
                          (isCollationEditMode.collation?.id === highlight.id &&
                            isCollationUpdating)
                            ? 'none'
                            : 'auto',
                      }}
                    >
                      {highlight?.text}
                    </Typography>
                  </LightTooltip>
                  <Stack
                    direction="column"
                    width={'inherit'}
                    borderTop={
                      highlight.status.code === 'PAGE_CHANGED'
                        ? '1px solid var(--yellow-300)'
                        : highlight.status.code === 'PAGE_NOT_MATCHED'
                          ? '1px solid var(--red-300)'
                          : '1px solid var(--gray-200)'
                    }
                    paddingBottom={'8px'}
                    textAlign={'left'}
                  >
                    {isCollationExpanded ===
                      `collationPanel${highlight.id}` && (
                      <LinearProgress
                        sx={{
                          width: '100%',
                          visibility: resultAreaLoading ? 'visible' : 'hidden',
                        }}
                      />
                    )}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                      width={'inherit'}
                      paddingY={'8px'}
                      textAlign={'left'}
                      marginTop={'8px'}
                      style={{
                        opacity:
                          isCollationExpanded ===
                            `collationPanel${highlight.id}` && resultAreaLoading
                            ? 0.5
                            : 1,
                        pointerEvents: resultAreaLoading ? 'none' : 'auto',
                      }}
                    >
                      {highlight.status.code !== 'INHERITED' ? (
                        <div style={{ width: 'inherit' }}>
                          {t('Rank: ')}{' '}
                          {highlight.status.code === 'PAGE_NOT_MATCHED'
                            ? 'Not Available'
                            : highlight.rank}
                        </div>
                      ) : (
                        <>
                          <Tooltip title={t('Information')}>
                            <IconButton
                              sx={{
                                '&:hover': { backgroundColor: 'transparent' },
                              }}
                              onClick={handleClickPhraseInfo}
                              size="small"
                              style={{ pointerEvents: 'auto' }}
                              aria-controls={
                                openPhraseInfo ? 'phrase-info-menu' : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                openPhraseInfo ? 'true' : undefined
                              }
                            >
                              {openPhraseInfo ? (
                                <FilledInfoIcon />
                              ) : (
                                <span className="icon-wrapper">
                                  <InfoIcon className="default-icon" />
                                  <ActiveInfoIcon className="active-icon" />
                                </span>
                              )}
                            </IconButton>
                          </Tooltip>
                          <PhraseInfoMenu
                            anchorElPhrase={anchorElPhrase}
                            openPhraseInfo={openPhraseInfo}
                            handleClosePhraseInfo={handleClosePhraseInfo}
                            t={t}
                          />
                        </>
                      )}
                      {highlight?.status?.message && (
                        <LightTooltip
                          key={highlight.id}
                          style={{ display: 'block' }}
                          placement="left"
                          arrow
                          title={
                            <span
                              dangerouslySetInnerHTML={{
                                __html: highlight.status.message,
                              }}
                            />
                          }
                        >
                          <img
                            src={infoCircleIcon}
                            alt="Info Circle Icon"
                            style={{
                              cursor: 'pointer',
                              width: '20px',
                              height: '20px',
                              marginBottom: '3px',
                            }}
                          />
                        </LightTooltip>
                      )}
                      <Box
                        display="flex"
                        width={'inherit'}
                        justifyContent={'end'}
                        gap={1}
                      >
                        {isCollationEditMode.collation?.id === highlight?.id &&
                        isCollationUpdating ? (
                          <CircularProgress size={20} />
                        ) : isCollationEditMode?.isEditMode &&
                          isCollationEditMode.collation.id === highlight.id ? (
                          <img
                            src={filledEditIcon}
                            alt="Edit"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                              e.stopPropagation()
                              setSelectedResultId(
                                highlight.collation_results[0].id,
                              )
                              setIsCollationEditMode({
                                isEditMode: false,
                                phraseId: null,
                                collation: null,
                              })
                            }}
                          />
                        ) : (
                          <img
                            src={editIcon}
                            alt="Edit"
                            style={{
                              pointerEvents:
                                isCollationExpanded ===
                                  `collationPanel${highlight.id}` &&
                                resultAreaLoading
                                  ? 'none'
                                  : 'auto',
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              setIsCollationExpanded('')
                              setSelectedResultId(
                                highlight?.collation_results[0]?.id,
                              )
                              updateHash(
                                phraseId,
                                highlight?.collation_results[0]?.id,
                                highlight.id,
                              )
                              setHighlightList(highlight?.collation_results)
                              setSelectedCollation([
                                highlight?.collation_results[0],
                              ])
                              setCurrentPage(
                                highlight?.collation_results[0].page,
                              )
                              if (
                                localStorage.getItem(
                                  `update_dialog_from_collations_projectID_${projectId}`,
                                ) === 'true'
                              ) {
                                setActiveCollationId(highlight)
                                setHighlightList([])
                                const hashParts =
                                  document.location.hash.split(';') // Split the hash by ';'
                                document.location.hash = hashParts[0] + ';'

                                setManualCollation({
                                  isEditMode: false,
                                  phraseId: null,
                                })
                                setIsCollationEditMode({
                                  isEditMode: true,
                                  phraseId,
                                  collation: highlight,
                                })
                              } else {
                                handleOpenReplaceConfirmDialog(
                                  'update',
                                  highlight,
                                )
                                setIsCollationEditMode({
                                  isEditMode: true,
                                  phraseId,
                                  collation: highlight,
                                })

                                // setSelectedCollation([])
                                setManualCollation({
                                  isEditMode: false,
                                  phraseId: null,
                                })
                              }
                            }}
                          />
                        )}
                        {highlight.status.code !== 'PAGE_NOT_MATCHED' ? (
                          <img
                            src={
                              isCollationExpanded ===
                              `collationPanel${highlight.id}`
                                ? upCircleIcon
                                : downCircleIcon
                            }
                            alt="Edit"
                            style={{
                              pointerEvents:
                                (isCollationEditMode.collation?.id ===
                                  highlight?.id &&
                                  isCollationUpdating) ||
                                (isCollationExpanded ===
                                  `collationPanel${highlight.id}` &&
                                  resultAreaLoading)
                                  ? 'none'
                                  : 'auto',
                            }}
                            onClick={() => {
                              handleChange(
                                `collationPanel${highlight.id}`,
                                highlight,
                              )
                            }}
                          />
                        ) : (
                          <DeleteIcon
                            style={{
                              cursor: 'pointer',
                              pointerEvents: 'auto',
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleAcknowledge(highlight.id)
                            }}
                          />
                        )}
                      </Box>
                    </Stack>
                    <Stack
                      display={'flex'}
                      direction={'row'}
                      flexWrap={'wrap'}
                      gap={1}
                    >
                      {isCollationExpanded !==
                        `collationPanel${highlight.id}` &&
                        highlight.status.code !== 'PAGE_NOT_MATCHED' &&
                        highlight?.collation_results?.map(
                          (collation, index) => {
                            const phraseContent = (
                              <Box
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (
                                    selectedCollation[0] &&
                                    selectedCollation[0].id === collation.id
                                  ) {
                                    setIsCollationExpanded('')
                                    setSelectedResultId(null)
                                    const hashParts =
                                      document.location.hash.split(';') // Split the hash by ';'
                                    document.location.hash = hashParts[0] + ';'
                                    setHighlightList(
                                      highlight?.collation_results,
                                    )
                                    setSelectedCollation([])
                                  } else {
                                    setIsCollationExpanded('')
                                    setSelectedResultId(collation?.id)
                                    updateHash(
                                      phraseId,
                                      collation?.id,
                                      highlight.id,
                                    )
                                    setHighlightList(
                                      highlight?.collation_results,
                                    )
                                    setSelectedCollation([collation])
                                    setCurrentPage(collation.page)
                                  }
                                }}
                                style={{
                                  backgroundColor:
                                    collation?.status === 'PAGE_CHANGED'
                                      ? 'var(--yellow-300)'
                                      : collation?.verification?.status ===
                                            false ||
                                          collation?.status ===
                                            'PAGE_NOT_MATCHED'
                                        ? 'var(--red-200)'
                                        : collation?.verification?.status
                                          ? 'var(--green-200)'
                                          : 'var(--gray-100)',
                                  pointerEvents:
                                    (isCollationEditMode.collation?.id ===
                                      highlight?.id &&
                                      isCollationUpdating) ||
                                    (isCollationExpanded ===
                                      `collationPanel${highlight?.id}` &&
                                      resultAreaLoading)
                                      ? 'none'
                                      : 'auto',
                                  fontWeight: 500,
                                  padding: '5px',
                                  minWidth: '20px',
                                  textAlign: 'center',
                                  borderRadius: '4px',
                                  border:
                                    selectedResultId === collation?.id &&
                                    !isCollationUpdating
                                      ? collation?.status === 'PAGE_CHANGED'
                                        ? '1px solid var(--yellow-500)'
                                        : collation?.verification?.status ===
                                              false ||
                                            collation?.status ===
                                              'PAGE_NOT_MATCHED'
                                          ? '1px solid var(--red-500)'
                                          : collation?.verification?.status
                                            ? '1px solid var(--green-400)'
                                            : '1px solid var(--surface-700)'
                                      : 'none',
                                }}
                                id={`highlight${collation?.id}`}
                                position={'relative'}
                              >
                                {collation?.page}
                                {collation?.approval_status.status && (
                                  <Box
                                    sx={{
                                      width: 16,
                                      height: 16,
                                      position: 'absolute',
                                      top: -5,
                                      right: -7,
                                    }}
                                  >
                                    <CircleFilledCheckIcon />
                                  </Box>
                                )}
                              </Box>
                            )
                            // return collation?.message ? (
                            //   <LightTooltip
                            //     key={collation?.id}
                            //     style={{ display: 'block' }}
                            //     placement="top-start"
                            //     arrow
                            //     title={
                            //       <Box>
                            //         <Typography fontWeight="bold">
                            //           {collation.status === 'PAGE_CHANGED'
                            //             ? t('Page Number(s) Changed')
                            //             : collation.status ===
                            //                 'PAGE_NOT_MATCHED'
                            //               ? t('Page did not match')
                            //               : null}
                            //         </Typography>
                            //         <Typography>
                            //           {collation.status === 'PAGE_NOT_MATCHED'
                            //             ? collation.message
                            //             : 'The page from the old document was not found in the updated document'}
                            //         </Typography>
                            //       </Box>
                            //     }
                            //   >
                            //     {phraseContent}
                            //   </LightTooltip>
                            // ) :
                            return (
                              <React.Fragment key={collation?.id}>
                                {phraseContent}
                              </React.Fragment>
                            )
                          },
                        )}
                    </Stack>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    opacity:
                      isCollationExpanded ===
                        `collationPanel${highlight?.id}` && resultAreaLoading
                        ? 0.5
                        : 1,
                  }}
                >
                  {/* Check if the highlight content exists */}
                  {highlight?.text &&
                  isCollationExpanded === `collationPanel${highlight?.id}` ? (
                    <div
                      style={{
                        width: 'inherit',

                        // Change text color if this highlight is selected
                        color:
                          highlightList && highlightList?.id === highlight?.id
                            ? '#000'
                            : '',
                      }}
                    >
                      <Stack gap={1}>
                        <PageDisplay
                          highlightId={highlight.id}
                          highlightList={highlightList}
                          parseIdFromHash={parseIdFromHash}
                          updateHash={updateHash}
                          colorHighlight={colorHighlight}
                          darkColor={darkColor}
                          handleLike={handleLike}
                          handleDislike={handleDislike}
                          projectId={projectId}
                          phraseId={phraseId}
                          handleDeleteCollation={handleDeleteCollation}
                          selectedResultId={selectedResultId}
                          setSelectedResultId={setSelectedResultId}
                          setSelectedCollation={setSelectedCollation}
                          isCollationExpanded={isCollationExpanded}
                          resultAreaLoading={resultAreaLoading}
                          isCollationUpdating={isCollationUpdating}
                          handleOpenReplaceConfirmDialog={
                            handleOpenReplaceConfirmDialog
                          }
                          t={t}
                          setCurrentPage={setCurrentPage}
                          refs={refs}
                          setActiveCollationId={setActiveCollationId}
                        />
                      </Stack>
                    </div>
                  ) : null}
                </div>
              </AccordionDetails>
            </Accordion>
          )
          return (
            <React.Fragment key={highlight.id}>
              {collationContent}
            </React.Fragment>
          )
        })}
      </div>
    </Stack>
  )
}

// Component for displaying the list of pages with optional separators
const PageDisplay = ({
  highlightList,
  highlightId,
  parseIdFromHash,
  updateHash,
  colorHighlight,
  darkColor,
  handleLike,
  handleDislike,
  projectId,
  phraseId,
  handleDeleteCollation,
  selectedResultId,
  setSelectedResultId,
  setSelectedCollation,
  isCollationExpanded,
  resultAreaLoading,
  isCollationUpdating,
  handleOpenReplaceConfirmDialog,
  t,
  setCurrentPage,
  refs,
  setActiveCollationId,
}) => {
  return (
    <>
      {highlightList?.length > 0 ? (
        highlightList?.map((collation, index) => (
          <Stack
            key={collation?.id}
            display="flex"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            sx={{
              borderRadius: '4px',
              border:
                selectedResultId === collation.id
                  ? collation?.status === 'PAGE_CHANGED'
                    ? '1px solid var(--yellow-500)'
                    : collation?.verification?.status === false ||
                        collation?.status === 'PAGE_NOT_MATCHED'
                      ? '1px solid var(--red-500)'
                      : collation?.verification?.status
                        ? '1px solid var(--green-400)'
                        : '1px solid var(--surface-700)'
                  : '#fff',
            }}
            onClick={(event) => {
              updateHash(phraseId, collation.id, highlightId)
              setSelectedResultId(collation?.id)
              setSelectedCollation([collation])
              setCurrentPage(collation.page)
              colorHighlight({
                highlight: collation,
                resFeedback: collation?.verification?.status,
                isActionClick: true,
              })
            }}
          >
            <Box
              sx={{
                backgroundColor:
                  collation?.status === 'PAGE_CHANGED'
                    ? 'var(--yellow-300)'
                    : collation?.verification?.status === false ||
                        collation?.status === 'PAGE_NOT_MATCHED'
                      ? 'var(--red-200)'
                      : collation?.verification?.status
                        ? 'var(--green-200)'
                        : 'var(--gray-100)',
                cursor: 'pointer',
                fontWeight: '500',
                padding: '5px',
                margin: '5px',
                borderRadius: '2px',
                minWidth: '20px',
                position: 'relative',
              }}
              id={`collation${collation?.id}`}
              ref={(el) => (refs.current[`collation${collation?.id}`] = el)}
            >
              {collation?.page}
              {collation?.approval_status.status && (
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    position: 'absolute',
                    top: -5,
                    right: -7,
                  }}
                >
                  <CircleFilledCheckIcon />
                </Box>
              )}
            </Box>
            <Box display="flex" gap={1} mr={'5px'}>
              <Tooltip title={t('Accept')} placement="top">
                {collation?.verification.status ? (
                  <FilledThumbUpIcon
                    className="like-dislike-btn"
                    style={{
                      opacity:
                        collation?.status === 'PAGE_NOT_MATCHED' ? 0.5 : 1,
                      pointerEvents:
                        (isCollationExpanded ===
                          `collationPanel${highlightId}` &&
                          resultAreaLoading) ||
                        isCollationUpdating ||
                        collation?.status === 'PAGE_NOT_MATCHED'
                          ? 'none'
                          : 'auto',
                    }}
                    onClick={() =>
                      handleLike(
                        collation,
                        projectId,
                        phraseId,
                        highlightId,
                        collation.id,
                      )
                    }
                    id={`like${collation.id}`}
                  />
                ) : (
                  <ThumbUpIcon
                    className="like-dislike-btn"
                    style={{
                      opacity:
                        collation?.status === 'PAGE_NOT_MATCHED' ? 0.5 : 1,
                      pointerEvents:
                        (isCollationExpanded ===
                          `collationPanel${highlightId}` &&
                          resultAreaLoading) ||
                        isCollationUpdating ||
                        collation?.status === 'PAGE_NOT_MATCHED'
                          ? 'none'
                          : 'auto',
                    }}
                    onClick={() =>
                      handleLike(
                        collation,
                        projectId,
                        phraseId,
                        highlightId,
                        collation.id,
                      )
                    }
                    id={`like${collation.id}`}
                  />
                )}
              </Tooltip>
              <Tooltip title={t('Reject')} placement="top">
                {collation?.verification.status === false ? (
                  <FilledThumbDownIcon
                    className="like-dislike-btn"
                    style={{
                      opacity:
                        collation?.status === 'PAGE_NOT_MATCHED' ? 0.5 : 1,
                      pointerEvents:
                        (isCollationExpanded ===
                          `collationPanel${highlightId}` &&
                          resultAreaLoading) ||
                        isCollationUpdating ||
                        collation?.status === 'PAGE_NOT_MATCHED'
                          ? 'none'
                          : 'auto',
                    }}
                    onClick={() =>
                      handleDislike(
                        collation,
                        projectId,
                        phraseId,
                        highlightId,
                        collation.id,
                      )
                    }
                    id={`dislike${collation.id}`}
                  />
                ) : (
                  <ThumbDownIcon
                    className="like-dislike-btn"
                    style={{
                      opacity:
                        collation?.status === 'PAGE_NOT_MATCHED' ? 0.5 : 1,
                      pointerEvents:
                        (isCollationExpanded ===
                          `collationPanel${highlightId}` &&
                          resultAreaLoading) ||
                        isCollationUpdating ||
                        collation?.status === 'PAGE_NOT_MATCHED'
                          ? 'none'
                          : 'auto',
                    }}
                    onClick={() =>
                      handleDislike(
                        collation,
                        projectId,
                        phraseId,
                        highlightId,
                        collation.id,
                      )
                    }
                    id={`dislike${collation.id}`}
                  />
                )}
              </Tooltip>
              <DeleteIcon
                style={{
                  cursor: 'pointer',
                  pointerEvents:
                    (isCollationExpanded === `collationPanel${highlightId}` &&
                      resultAreaLoading) ||
                    isCollationUpdating
                      ? 'none'
                      : 'auto',
                }}
                onClick={() => {
                  if (
                    localStorage.getItem(
                      `delete_dialog_from_collations_projectID_${projectId}`,
                    ) === 'true'
                  ) {
                    handleDeleteCollation(collation.id)
                    setActiveCollationId(collation)
                  } else {
                    handleOpenReplaceConfirmDialog('delete', collation)
                  }
                }}
              />
            </Box>
          </Stack>
        ))
      ) : (
        <Typography>{t('No collations are currently available.')}</Typography>
      )}
    </>
  )
}
// const CommentsContainer = ({ comments }: any) => (
//   <Stack>
//     {comments?.map((highlight, index) => (
//       // eslint-disable-next-line react/jsx-key
//       <Box key={index}></Box>
//     ))}
//   </Stack>
// )

function InfoMenu({ anchorEl, open, handleClose, t }) {
  // Prevent the menu from closing when clicking inside
  const handleMenuClick = (event) => {
    event.stopPropagation()
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id="info-menu"
      open={open}
      onClose={handleClose}
      onClick={handleMenuClick} // Changed to handleMenuClick to stop propagation
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 3.5,
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 1,
            right: 15,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <MenuItem disableRipple style={{ cursor: 'text' }}>
        <Typography fontWeight={600}>{t('Info')}</Typography>
      </MenuItem>
      <MenuItem
        disableRipple
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          cursor: 'text',
        }}
      >
        <Typography fontWeight={500}>
          {t(
            'performing manual collation will clear all results which are not verified.',
          )}
        </Typography>
      </MenuItem>
    </Menu>
  )
}
function PhraseInfoMenu({
  anchorElPhrase,
  openPhraseInfo,
  handleClosePhraseInfo,
  t,
}) {
  // Prevent the menu from closing when clicking inside
  const handleMenuClick = (event) => {
    event.stopPropagation()
  }

  return (
    <Menu
      anchorEl={anchorElPhrase}
      id="phrase-info-menu"
      open={openPhraseInfo}
      onClose={handleClosePhraseInfo}
      onClick={handleMenuClick} // Changed to handleMenuClick to stop propagation
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          boxShadow: 'none',
          mt: 3.5,
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 1,
            left: 15,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <MenuItem
        disableRipple
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          cursor: 'text',
        }}
      >
        <Typography fontWeight={500}>{t('Inherited Collation')}</Typography>
      </MenuItem>
    </Menu>
  )
}
