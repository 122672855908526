import axios from 'axios'
import axiosInstance from './http-interceptors'

const controllers = new Map<string, AbortController>()

const createOrReuseController = (key: string) => {
  if (controllers.has(key)) {
    // Abort the previous request
    controllers.get(key)?.abort()
  }
  // Create a new controller and store it
  const controller = new AbortController()
  controllers.set(key, controller)
  return controller
}
// 60, 53, 6, 58, 61, 62, 63, 64, 52, 3, 4, 5
// Function to call get API to get User Slots - API-60
export const getAvailableSlots = async () => {
  try {
    const response = await axiosInstance.get(`/api/accounts/slots/`)
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}
// Function to call pactch API to update User role/status - API-58
export const updateUserRoleOrStatus = async (userId, data) => {
  try {
    const response = await axiosInstance.patch(
      `/api/accounts/user/${userId}/`,
      data,
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call post API to invite User - API-3
export const inviteUser = async (data) => {
  try {
    const response = await axiosInstance.post(`/api/accounts/user/`, data)
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}
// Function to call post API to re-invite User - API-61
export const reInviteUser = async (userId) => {
  try {
    const response = await axiosInstance.post(
      `/api/accounts/user/${userId}/re-invite/`,
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to get project by user - API-62
export const getProjectsByUserId = async (userId, status) => {
  const controller = createOrReuseController(
    `getProjectByUserId-${userId}-${status}`,
  )
  try {
    const response = await axiosInstance.get(
      `/api/accounts/user/${userId}/projects/${status}/`,
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call delete API to bulk delete project by user - API-64
export const assignProjectsToUser = async (userId, data) => {
  try {
    const response = await axiosInstance.post(
      `/api/accounts/user/${userId}/projects/`,
      data,
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to get project by user - API-63
export const removeProjectsToUser = async (userId, data) => {
  try {
    const response = await axiosInstance.delete(
      `/api/accounts/user/${userId}/projects/`,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      { data },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to get project by user - API-53
export const getUsers = async (params) => {
  const controller = createOrReuseController(`getUserList`)
  try {
    const response = await axiosInstance.get(`/api/accounts/user/`, {
      params,
      signal: controller.signal,
    })
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to get project by user  - API-6
export const getUserById = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `/api/accounts/user/${userId}/projects/`,
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to get list of members or non-members by project ID  - API-52
export const getMembersList = async (projectId, status, params) => {
  try {
    const response = await axiosInstance.get(
      `/api/project/${projectId}/membership/${status}/`,
      {
        params: params,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to get list of members or non-members by project ID  - API-4
export const assignUsersToProject = async (projectId, data) => {
  try {
    const response = await axiosInstance.post(
      `/api/project/${projectId}/membership/members/`,
      data,
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to remove member by ID  - API-5
export const removeUserToProject = async (projectId, memeberId) => {
  try {
    const response = await axiosInstance.delete(
      `/api/project/${projectId}/membership/members/${memeberId}/`,
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}
