/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  Icon,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { useContext, useEffect, useState, useRef, useMemo } from 'react'
import {
  ProjectContext,
  StatusContext,
  TargetContext,
  highlightsContext,
  layoutContext,
} from '../../../context/globalContext'
import editIcon from '../../../assets/images/Edit.svg'
import redEditIcon from '../../../assets/images/RedEdit.svg'
import redFilledEditIcon from '../../../assets/images/RedFilledEdit.svg'
import amberEditIcon from '../../../assets/images/amberEdit.svg'
import amberFilledEditIcon from '../../../assets/images/amberFilledEdit.svg'
import filledEditIcon from '../../../assets/images/stop_edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import redDeleteIcon from '../../../assets/images/red_delete.svg'
import amberDeleteIcon from '../../../assets/images/amber_delete.svg'
import circleCloseIcon from '../../../assets/images/close_circle.svg'
import filledCircleCloseIcon from '../../../assets/images/filled_circle_close.svg'
import checkIcon from '../../../assets/images/check.svg'
import closeIcon from '../../../assets/images/close.svg'
import ActiveCloseIcon from '../../../assets/images/active_close.svg'
import infoIcon from '../../../assets/images/info.svg'
import infoCircleIcon from '../../../assets/images/info_circle.svg'
import { ReactComponent as MagniferIcon } from '../../../assets/images/magnifer.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/down_arrow.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/images/arrow_up.svg'
import { ReactComponent as FilledFilterIcon } from '../../../assets/images/filled_filter.svg'
import { ReactComponent as FilterIcon } from '../../../assets/images/filter.svg'
import { ReactComponent as CheckedIcon } from '../../../assets/images/filled_checkbox.svg'
import { ReactComponent as CheckBoxIcon } from '../../../assets/images/checkbox.svg'
import { ReactComponent as CircleCheckIcon } from '../../../assets/images/circle_check.svg'
import { ReactComponent as CircleFilledCheckIcon } from '../../../assets/images/circle_filled_check.svg'
import { ReactComponent as DangerIcon } from '../../../assets/images/danger.svg'
import { ReactComponent as DangerActiveIcon } from '../../../assets/images/danger_active.svg'
import restartIcon from '../../../assets/images/restart.svg'

import addIcon from '../../../assets/images/add_circle.svg'
import kebabIcon from '../../../assets/images/kebab.svg'
import './sourcePhrases.scss'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import TextButton from '../../../components/button/textButton'
import { enqueueSnackbar } from 'notistack'
import {
  acknowledgePhrase,
  createOrReuseController,
  deleteAreaById,
  deleteROI,
  deleteSourcePhraseByPhraseId,
  finalizeAreaByAreaId,
  finalizeVerificationAreaByAreaId,
  getAreaById,
  getAreaList,
  getCollation,
  getROIs,
  getSourcePhraseByPhraseId,
  getSourcePhrasesByAreaId,
  retryROI,
  updateAreaName,
  updateROI,
} from '../../../services/collation-service'
import moment from 'moment'
import LightTooltip from '../../../components/tooltip/tooltip'
import CancelActionConformationFormDialog from '../../../components/form-dialog/cancel-conformation'
import { useTranslation } from 'react-i18next'
import { getLocalizedPath } from '../../../utils/shareLocalizePath'
import axios from 'axios'

interface Props {
  targetFiles: any[]
  setShowTargetPdfAndCollation
  handleGetTargetFiles: any
  setTargetTitle: any
  isTargetDataLoading: any
  isTargetExpanded: any
  setIsTargetExpanded: any
  isROIExpanded: any
  setIsROIExpanded: any
  phraseRef: any
  setSelectedResultId: any
}

export function SourcePhrases({
  targetFiles,
  setShowTargetPdfAndCollation,
  handleGetTargetFiles,
  setTargetTitle,
  isTargetDataLoading,
  isTargetExpanded,
  setIsTargetExpanded,
  isROIExpanded,
  setIsROIExpanded,
  phraseRef,
  setSelectedResultId,
}: Props) {
  const {
    areaList,
    setAreaList,
    roiList,
    setRoiList,
    selectedHighlight,
    setSelectedHighlight,
    selectedHighlightId,
    setSelectedHighlightId,
    collationName,
    setCollationName,
    isPhraseEditMode,
    setIsPhraseEditMode,
    handleGetAreaList,
    handleGetROIs,
    showTargetPdfAndCollation,
    setPhraseId,
    phraseId,
    darkColor,
    setIsCollationDataLoading,
    setCollationList,
    isNewRoiAdded,
    setSelectedCollation,
    isManualCollationInProgress,
    isPhraseAddMode,
    setIsPhraseAddMode,
    isAreaSearched,
    setIsAreaSearched,
    highlightAllStates,
    setHighlightAllStates,
    handleProjectStatus,
    sourcePhrases,
    setSourcePhrases,
    isProgressComplete,
    areaQuery,
    setAreaQuery,
  } = useContext(highlightsContext)
  const { projectId } = useContext(ProjectContext)
  const { setTargetId } = useContext(TargetContext)
  const { projectStatus } = useContext(StatusContext)
  const { layout } = useContext(layoutContext)

  const [expanded, setExpanded] = useState<any>('')
  const [targetSize, setTargetSize] = useState(0)
  const [remainingSize, setRemainingSize] = useState(0)
  const [defaultLayout, setDefaultLayout] = useState([])
  const roiRef: any = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()
  const [queryParameters] = useSearchParams()

  const targetRef: any = useRef(null)

  // Memoize stringified versions of your lists to use as dependencies
  const memoizedRoiList = useMemo(() => JSON.stringify(roiList), [roiList])
  const memoizedAreaList = useMemo(() => JSON.stringify(areaList), [areaList])
  const [isRoiLoading, setIsRoiLoading] = useState(true)
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const basePath = getLocalizedPath(currentLanguage, location.pathname)

  useEffect(() => {
    if (
      areaList?.length > 0 &&
      targetFiles?.length > 0 &&
      roiList?.length > 0
    ) {
      if (isTargetExpanded) {
        roiRef.current.expand()
        setIsROIExpanded(true)
        phraseRef.current.setLayout([30, 35, 35])
      } else {
        roiRef.current.expand()
        setIsROIExpanded(true)
        phraseRef.current.setLayout([45, 35, 20])
      }
    } else if (
      areaList?.length > 0 &&
      roiList?.length === 0 &&
      targetFiles?.length > 0
    ) {
      phraseRef.current.setLayout([80, 0, 20])
    } else if (
      areaList?.length > 0 &&
      roiList?.length > 0 &&
      targetFiles?.length === 0
    ) {
      roiRef.current.expand()
      setIsROIExpanded(true)
      phraseRef.current.setLayout([65, 35, 0])
    } else if (
      areaList?.length > 0 &&
      roiList?.length === 0 &&
      targetFiles?.length === 0
    ) {
      phraseRef.current.setLayout([100, 0, 0])
    } else if (
      areaList?.length === 0 &&
      roiList?.length > 0 &&
      targetFiles?.length > 0
    ) {
      roiRef.current.expand()
      setIsROIExpanded(true)
      phraseRef.current.setLayout([0, 80, 20])
    } else if (
      areaList?.length === 0 &&
      roiList?.length > 0 &&
      targetFiles?.length === 0
    ) {
      roiRef.current.expand()
      setIsROIExpanded(true)
      phraseRef.current.setLayout([0, 100, 0])
    } else {
      roiRef.current.expand()
      setIsROIExpanded(true)
      phraseRef.current.setLayout([45, 35, 20])
    }
  }, [memoizedRoiList])
  useEffect(() => {
    // By Default to make target panel collapse
    setIsTargetExpanded(false)
    // Collapse the target panel
    targetRef.current.collapse()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const totalHeightPx = window.innerHeight
      const fixedHeightPx = window.innerHeight <= 500 ? 200 : 170
      const remainingHeightPx = totalHeightPx - fixedHeightPx
      setRemainingSize(Math.round(remainingHeightPx / totalHeightPx) * 100)
      setTargetSize(Math.round(fixedHeightPx / totalHeightPx) * 100)
      // Set other panels based on remainingSize
    }
    window.addEventListener('resize', handleResize)
    handleResize() // Ensure it runs on mount as well

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const totalHeightPx = window.innerHeight
    const fixedHeightPx = window.innerHeight <= 500 ? 200 : 170
    const remainingHeightPx = totalHeightPx - fixedHeightPx
    setRemainingSize((remainingHeightPx / totalHeightPx) * 100)
    setTargetSize((fixedHeightPx / totalHeightPx) * 100)
  }, [])
  useEffect(() => {
    if (roiList?.length > 0 && isRoiLoading) setIsRoiLoading(false)
  }, [roiList])

  const handleGetRoIToPoll = (id) => {
    getROIs(id)
      .then((res) => {
        if (res.status === 200) {
          setIsRoiLoading(false)
          setRoiList(res?.data?.data)
        }
      })
      .catch((error) => {
        setIsRoiLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  // Polls for ROIs at intervals, updates state and checks for changes
  const statusInterval = useRef<any>(null)
  const prevStatuses = useRef<any>(roiList?.map((roi) => roi.status))

  const checkStatusChanges = () => {
    // Check if any ROI statuses have changed
    return !roiList?.every(
      (roi, index) => roi.status === prevStatuses.current[index],
    )
  }

  useEffect(() => {
    // Start or restart polling when ROIs are processing or in queue
    const startPolling = () => {
      if (
        !statusInterval.current &&
        roiList?.some(
          (roi) => roi.status === 'PROCESSING' || roi.status === 'IN_QUEUE',
        )
      ) {
        statusInterval.current = setInterval(() => {
          handleGetRoIToPoll(projectId)
        }, 5000)
      } else if (roiList?.some((roi) => roi.status === 'SUCCESS')) {
        selectedHighlight.isRoi && setSelectedHighlight([])
        selectedHighlight.isRoi && setSelectedHighlightId('')
      }
    }

    // Stop polling
    const stopPolling = () => {
      if (statusInterval.current) {
        clearInterval(statusInterval.current)
        statusInterval.current = null
        handleGetAreaList(projectId)
      }
    }

    const anyROIsProcessing = roiList?.some(
      (roi) => roi.status === 'PROCESSING' || roi.status === 'IN_QUEUE',
    )
    if (anyROIsProcessing) {
      startPolling()
    } else {
      stopPolling()
    }
    // Update the UI only if statuses change
    if (checkStatusChanges()) {
      setIsRoiLoading(false) // Or trigger any other state update for re-rendering
      prevStatuses.current = roiList?.map((roi) => roi.status) // Update the snapshot of statuses
      handleGetAreaList(projectId)
    }

    return () => {
      stopPolling()
    }
  }, [isNewRoiAdded, roiList, memoizedRoiList]) // Include all dependencies necessary for effect

  // Function to handle changes in the ROI (Region of Interest)
  const handleROIChange = () => {
    // Check if the ROI panel is currently collapsed
    if (roiRef?.current?.isCollapsed()) {
      if (targetRef?.current?.isCollapsed()) {
        // Update state to reflect the ROI panel is expanded
        setIsROIExpanded(true)
        // Expand the ROI panel
        roiRef.current.expand()
        // Adjust the layout to accommodate the expanded ROI panel
        phraseRef.current.setLayout([30, 30, 15])
      } else {
        // Update state to reflect the ROI panel is expanded
        setIsROIExpanded(true)
        // Expand the ROI panel
        roiRef.current.expand()
        // Adjust the layout to accommodate the expanded ROI panel
        phraseRef.current.setLayout([30, 35, 35])
      }
    } else if (roiRef?.current?.isExpanded()) {
      if (targetRef?.current?.isCollapsed()) {
        // Collapse the ROI panel
        roiRef.current.collapse()

        // Update state to reflect the ROI panel is not expanded
        setIsROIExpanded(false)

        // Adjust the layout to the default state
        phraseRef.current.setLayout([75, 10, 15])
      } else {
        // Collapse the ROI panel
        roiRef.current.collapse()

        // Update state to reflect the ROI panel is not expanded
        setIsROIExpanded(false)

        // Adjust the layout to the default state
        phraseRef.current.setLayout([55, 10, 35])
      }
    }
  }

  // Function to handle changes in the target panel
  const handleTargetChange = () => {
    // Check if the target panel is currently collapsed
    if (targetRef?.current?.isCollapsed()) {
      // Check if there are any image highlights
      if (roiList.length > 0) {
        // Expand the target panel
        targetRef.current.expand()
        // Collapse the ROI panel
        roiRef.current.collapse()
        // Update state to reflect the target panel is expanded
        setIsTargetExpanded(true)
        // Update state to reflect the ROI panel is not expanded
        setIsROIExpanded(false)
        // Adjust the layout to accommodate the expanded target panel and the presence of image highlights
        phraseRef.current.setLayout([50, 10, 40])
      } else {
        // Expand the target panel
        targetRef.current.expand()
        // Update state to reflect the target panel is expanded
        setIsTargetExpanded(true)
        // Adjust the layout to accommodate the expanded target panel without image highlights
        phraseRef.current.setLayout([60, 0, 40])
      }
    } else if (targetRef?.current?.isExpanded()) {
      // Check if there are any image highlights
      if (roiList?.length > 0) {
        // Collapse the target panel
        targetRef.current.collapse()
        // Update state to reflect the target panel is not expanded
        setIsTargetExpanded(false)
        // Update state to reflect the ROI panel is not expanded
        setIsROIExpanded(false)
        // Adjust the layout to the default state with image highlights
        phraseRef.current.setLayout([70, 10, 20])
      } else {
        // Collapse the target panel
        targetRef.current.collapse()
        // Update state to reflect the target panel is not expanded
        setIsTargetExpanded(false)
        // Adjust the layout to the default state without image highlights
        phraseRef.current.setLayout([80, 0, 20])
      }
    }
  }
  const handleLayout = (sizes) => {
    if (sizes && phraseRef.current.getLayout()) {
      phraseRef.current.setLayout(sizes)
    }
  }
  const parsePhraseIdFromHash = () => {
    const hash = document.location.hash.substr(1)
    const parts = hash.split(';')
    const state = {
      phrase: parts[0].slice('#phrase-'.length) || '',
      collation: parts.length > 1 ? parts[1].slice('collation-'.length) : '',
    }
    return state.phrase
  }
  const parseCollationIdFromHash = () => {
    const hash = document.location.hash.substr(1)
    const parts = hash.split(';')
    const state = {
      phrase: parts[0].slice('#phrase-'.length) || '',
      collation: parts.length > 1 ? parts[1].slice('collation-'.length) : '',
    }
    return state.collation
  }
  const updateHash = (
    highlight: any,
    areaId?: any,
    phraseId?: any,
    collationResultId?: any,
  ) => {
    // Construct the hash part based on available data
    const newHash = collationResultId
      ? `#phrase-${highlight.id};collation-${parseCollationIdFromHash()}`
      : `#phrase-${highlight.id}`
    if (highlight.isRoi) {
      // Remove specific query parameters if highlight.isRoi is true
      queryParameters.delete('area_id')
      queryParameters.delete('phrase_id')
      queryParameters.delete('collation_id')
    } else {
      // Update or set query parameters as necessary
      if (queryParameters.get('project_id')) {
        queryParameters.set(
          'project_id',
          queryParameters.get('project_id') ?? '',
        )
      }
      if (queryParameters.get('target_id')) {
        queryParameters.set('target_id', queryParameters.get('target_id') ?? '')
      }
      if (areaId) queryParameters.set('area_id', areaId)
      if (phraseId) queryParameters.set('phrase_id', phraseId)
      if (collationResultId) {
        queryParameters.set('collation_id', collationResultId)
      }

      if (!collationResultId) {
        queryParameters.delete('collation_id')
      }
    }
    // Construct the full URL including the hash
    const fullUrl = `${basePath}?${queryParameters.toString()}`

    // Use navigate to update the URL without reloading the page
    navigate(fullUrl, { state: { location }, replace: true })

    document.location.hash = newHash
  }
  const handleGetCollation = (phraseId, targetId) => {
    // getCollation(1, 1, 9)
    if (phraseId && targetId) {
      setIsCollationDataLoading(true)
      setCollationList(null)
      setPhraseId(phraseId)
      setSelectedResultId(null)
      setSelectedCollation([])
      getCollation(projectId, phraseId, targetId)
        .then((res) => {
          if (res.status === 200) {
            const updatedCollationList = res?.data?.data?.map((collation) => {
              // Map over collation_results to add feedback and colour
              const updatedCollationResults = collation?.collation_results?.map(
                (result) => {
                  const color = darkColor(result.verification.status)
                  return {
                    ...result,
                    colour: color,
                  }
                },
              )

              // Return the collation with the updated collation_results
              return {
                ...collation,
                collation_results: updatedCollationResults,
              }
            })
            setCollationList(updatedCollationList) // Update the state with the new collation list
            setIsCollationDataLoading(false)
            // setHighlightList(updatedCollationList[0].collation_results)
            // document.location.hash = `phrase-${updatedCollationList[0].collation_results[0].id}`
          }
        })
        .catch((error) => {
          // To handle cancel axios error
          if (axios.isCancel(error)) {
            console.debug('Request canceled:', error)
          } else if (
            error?.response?.data?.detail &&
            error?.response?.request?.status !== 500 &&
            error?.response?.request?.status !== 502
          ) {
            setIsCollationDataLoading(false)
            enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
              variant: 'error',
              persist: true,
            })
          } else if (error?.response?.request?.status === 502) {
            setIsCollationDataLoading(false)
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
    }
  }

  return (
    <Stack
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      direction={'column'}
      width={'100%'}
      height={'100%'}
      overflow={'auto'}
      style={{ height: 'calc(100% - 40px)' }}
    >
      <Box width={'100%'} py={'1rem'} height={'inherit'}>
        <Box px={'1rem'} display="flex" justifyContent={'space-between'}></Box>
        <PanelGroup
          direction="vertical"
          ref={phraseRef}
          onLayout={handleLayout}
        >
          <Panel
            id="areaListPanel"
            minSize={areaList?.length > 0 ? 15 : 10}
            order={1}
            defaultSize={defaultLayout[0]}
          >
            <Paper
              className="webkit-fill-available moz-available fill-available"
              style={{
                width: '100%',
                height: '100%',
                padding: '10px',
                overflowY: 'auto',
                boxShadow: 'none',
              }}
            >
              <SearchHighlightsList
                updateHash={updateHash}
                selectedHighlightId={selectedHighlightId}
                setSelectedHighlightId={setSelectedHighlightId}
                setSelectedHighlight={setSelectedHighlight}
                areaList={areaList}
                setAreaList={setAreaList}
                handleGetAreaList={handleGetAreaList}
                collationName={collationName}
                setCollationName={setCollationName}
                projectId={projectId}
                isPhraseEditMode={isPhraseEditMode}
                setIsPhraseEditMode={setIsPhraseEditMode}
                showTargetPdfAndCollation={showTargetPdfAndCollation}
                setPhraseId={setPhraseId}
                phraseId={phraseId}
                darkColor={darkColor}
                projectStatus={projectStatus}
                expanded={expanded}
                setExpanded={setExpanded}
                handleGetTargetFiles={handleGetTargetFiles}
                setIsCollationDataLoading={setIsCollationDataLoading}
                setShowTargetPdfAndCollation={setShowTargetPdfAndCollation}
                navigate={navigate}
                queryParameters={queryParameters}
                location={location}
                handleGetCollation={handleGetCollation}
                setCollationList={setCollationList}
                layout={layout}
                isManualCollationInProgress={isManualCollationInProgress}
                setSelectedCollation={setSelectedCollation}
                selectedHighlight={selectedHighlight}
                parseCollationIdFromHash={parseCollationIdFromHash}
                isPhraseAddMode={isPhraseAddMode}
                setIsPhraseAddMode={setIsPhraseAddMode}
                t={t}
                basePath={basePath}
                isAreaSearched={isAreaSearched}
                setIsAreaSearched={setIsAreaSearched}
                highlightAllStates={highlightAllStates}
                setHighlightAllStates={setHighlightAllStates}
                handleProjectStatus={handleProjectStatus}
                sourcePhrases={sourcePhrases}
                setSourcePhrases={setSourcePhrases}
                isProgressComplete={isProgressComplete}
                targetFiles={targetFiles}
                areaQuery={areaQuery}
                setAreaQuery={setAreaQuery}
              />
            </Paper>
          </Panel>
          <PanelResizeHandle
            disabled={!(areaList?.length > 0)}
            className="horizontal-sash"
            style={{ width: '10px', direction: 'rtl' }}
          />
          <Panel
            id="roiListPanel"
            ref={roiRef}
            order={2}
            minSize={roiList?.length > 0 ? 20 : 0}
            collapsible
            collapsedSize={roiList?.length > 0 ? 15 : 0}
            defaultSize={defaultLayout[1]}
            style={{
              display: roiList?.length > 0 ? 'block' : 'none',
            }}
            onCollapse={() => {
              setIsROIExpanded(false)
              if (
                selectedHighlight?.length > 0 &&
                selectedHighlight[0]?.isRoi
              ) {
                document.location.hash = ''
                setSelectedHighlightId(null)
                setSelectedHighlight([])
                setCollationList(null)
              }
            }}
            onExpand={(): void => {
              setIsROIExpanded(true)
              if (roiList.length > 0) {
                const firstHighlight = roiList[0]
                const modifiedHighlights = {
                  ...firstHighlight,
                  position: {
                    boundingRect: { ...firstHighlight.position },
                    rects: [{ ...firstHighlight.position }],
                    pageNumber: firstHighlight.page,
                  },
                  isRoi: true,
                }
                setSelectedHighlightId(modifiedHighlights.id)
                setSelectedHighlight([modifiedHighlights])
                setCollationList(null)
                updateHash(modifiedHighlights)
              }
            }}
          >
            <Paper
              className="webkit-fill-available moz-available fill-available"
              style={{
                height: '100%',
                width: '100%',
                padding: '10px',
                boxShadow: 'none',
                overflowY: 'auto',
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'} py={2}>
                <Typography textAlign={'left'} ml={1} mb={3}>
                  {t('RoI Status')}
                  <Badge
                    style={{
                      marginLeft: '15px',
                    }}
                    showZero
                    color="secondary"
                    badgeContent={(roiList as any[])?.length}
                  ></Badge>{' '}
                </Typography>
                {isROIExpanded ? (
                  <ArrowUpIcon
                    onClick={() => {
                      handleROIChange()
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <ArrowDownIcon
                    onClick={() => {
                      handleROIChange()
                      setExpanded('')
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </Box>
              {isROIExpanded ? (
                <ROIStatus
                  roiList={roiList}
                  handleROIChange={handleROIChange}
                  isROIExpanded={isROIExpanded}
                  updateHash={updateHash}
                  selectedHighlightId={selectedHighlightId}
                  selectedHighlight={selectedHighlight}
                  setSelectedHighlightId={setSelectedHighlightId}
                  setSelectedHighlight={setSelectedHighlight}
                  setRoiList={setRoiList}
                  handleGetROIs={handleGetROIs}
                  projectId={projectId}
                  setCollationName={setCollationName}
                  setIsPhraseEditMode={setIsPhraseEditMode}
                  handleGetAreaList={handleGetAreaList}
                  navigate={navigate}
                  queryParameters={queryParameters}
                  projectStatus={projectStatus}
                  isNewRoiAdded={isNewRoiAdded}
                  memoizedRoiList={memoizedRoiList}
                  isRoiLoading={isRoiLoading}
                  isManualCollationInProgress={isManualCollationInProgress}
                  isPhraseAddMode={isPhraseAddMode}
                  setIsPhraseAddMode={setIsPhraseAddMode}
                  setCollationList={setCollationList}
                  t={t}
                  basePath={basePath}
                  isProgressComplete={isProgressComplete}
                />
              ) : null}
            </Paper>
          </Panel>
          <PanelResizeHandle
            className="horizontal-sash"
            style={{ width: '10px', direction: 'rtl' }}
          />

          <Panel
            id="targetListPanel"
            collapsible
            collapsedSize={targetFiles?.length > 0 ? 20 : 0}
            ref={targetRef}
            minSize={20}
            order={3}
            onCollapse={() => {
              setIsTargetExpanded(false)
            }}
            onExpand={(): void => {
              setIsTargetExpanded(true)
            }}
            style={{ display: targetFiles?.length > 0 ? 'block' : 'none' }}
          >
            <Paper
              className="webkit-fill-available moz-available fill-available"
              style={{
                width: '100%',
                padding: '10px',
                height: '100%',
                boxShadow: 'none',
                overflowY: 'auto',
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'} pt={2}>
                <Typography textAlign={'left'} ml={1} mb={3}>
                  {t('Target Documents')}
                  <Badge
                    badgeContent={
                      targetFiles?.length > 0 ? targetFiles?.length : 0
                    }
                    showZero
                    color="secondary"
                    style={{ marginLeft: '15px' }}
                  ></Badge>
                </Typography>
                {isTargetExpanded ? (
                  <ArrowUpIcon
                    onClick={() => {
                      handleTargetChange()
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <ArrowDownIcon
                    onClick={() => {
                      handleTargetChange()
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </Box>
              {isTargetExpanded ? (
                <TargetDocument
                  targetFiles={targetFiles}
                  setShowTargetPdfAndCollation={setShowTargetPdfAndCollation}
                  location={location}
                  navigate={navigate}
                  queryParameters={queryParameters}
                  setTargetId={setTargetId}
                  handleGetCollation={handleGetCollation}
                  setTargetTitle={setTargetTitle}
                  isTargetDataLoading={isTargetDataLoading}
                  setCollationList={setCollationList}
                  projectStatus={projectStatus}
                  isManualCollationInProgress={isManualCollationInProgress}
                  setSelectedCollation={setSelectedCollation}
                  t={t}
                  basePath={basePath}
                  isProgressComplete={isProgressComplete}
                />
              ) : null}
            </Paper>
          </Panel>
        </PanelGroup>
      </Box>
    </Stack>
  )
}

// eslint-disable-next-line react/prop-types
const SearchHighlightsList = ({
  updateHash,
  selectedHighlightId,
  setSelectedHighlightId,
  selectedHighlight,
  setSelectedHighlight,
  areaList,
  setAreaList,
  collationName,
  setCollationName,
  projectId,
  handleGetAreaList,
  isPhraseEditMode,
  setIsPhraseEditMode,
  showTargetPdfAndCollation,
  phraseId,
  setPhraseId,
  darkColor,
  projectStatus,
  expanded,
  setExpanded,
  handleGetTargetFiles,
  setIsCollationDataLoading,
  setShowTargetPdfAndCollation,
  navigate,
  location,
  queryParameters,
  handleGetCollation,
  setCollationList,
  layout,
  isManualCollationInProgress,
  setSelectedCollation,
  parseCollationIdFromHash,
  isPhraseAddMode,
  setIsPhraseAddMode,
  t,
  basePath,
  isAreaSearched,
  setIsAreaSearched,
  highlightAllStates,
  setHighlightAllStates,
  handleProjectStatus,
  sourcePhrases,
  setSourcePhrases,
  isProgressComplete,
  targetFiles,
  areaQuery,
  setAreaQuery,
}) => {
  // Initialize expanded state to a specific value to make it controlled from the start
  const [tempItemName, setTempItemName] = useState('') // Temporarily stores the new name being entered
  const [editingItemId, setEditingItemId] = useState(null)
  const [editPhraseModeId, setEditPhraseModeId] = useState(null)
  const [interactedItemId, setInteractedItemId] = useState(null)
  const [activePopoverId, setActivePopoverId] = useState(null)
  const [] = useState('')
  const [addIconId, setAddIconId] = useState(null) // Tracks the ID of the item whose add icon should show as a close icon
  const [isDataLoading, setIsDataLoading] = useState(true) // Tracks the ID of the item whose add icon should show as a close icon
  const [areaListLoading, setAreaListLoading] = useState(true) // Tracks the ID of the item whose add icon should show as a close icon
  const { targetId } = useContext(TargetContext)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const openKebabMenu = Boolean(anchorEl)
  const id = openKebabMenu ? 'simple-popover' : undefined
  const [dialogState, setDialogState] = useState<any>({
    isOpen: false,
    area: {},
    phrase: {},
    type: null, // 'delete' or 'update'
    msg: null, // 'delete' or 'update'
  })
  const [sourceCount, setSourceCount] = useState<any>(0)
  const [dangerTipSatate, setDangerTipState] = useState<any>({})
  const [clickedArea, setClickedArea] = useState<any>(null)
  const boxRef = useRef<any>(null)

  const handleShowDangerTip = (id) => {
    setClickedArea(id)
    setDangerTipState((prevState) => ({
      ...prevState,
      [id]: true, // Set the tip to show for this specific item.id
    }))
  }

  const handleHideDangerTip = (id) => {
    setDangerTipState((prevState) => ({
      ...prevState,
      [id]: false, // Set the tip to hide for this specific item.id
    }))
  }

  useEffect((): any => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setDangerTipState((prevState) => ({
          ...prevState,
          [clickedArea]: false, // Hide the tip for the current area_id
        }))
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    // Cleanup function to remove event listener when component unmounts or areaId changes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [clickedArea]) // Only re-run the effect when areaId changes

  // to render phrase highlight and collation highlight whenever Area list change
  // Along with expanding both Area and collation Accordion panel
  useEffect(() => {
    // To render both source phrase and target phrase & expand their respective Accordion Panel
    if (
      projectId &&
      queryParameters.get('area_id') &&
      queryParameters.get('phrase_id') &&
      queryParameters.get('collation_id')
    ) {
      setExpanded(`searchPhrasePanel${queryParameters.get('area_id')}`)
      setIsDataLoading(true)
      setSourcePhrases([])
      getSourcePhrasesByAreaId(projectId, queryParameters.get('area_id'))
        .then((response) => {
          setSourcePhrases(response.data.data)
          if (response?.data?.data?.length === 0) {
            setSelectedHighlightId(null)
            setSelectedHighlight([])
            document.location.hash = ''
            setShowTargetPdfAndCollation(false) // to go back to 3 split panel mode
            navigate(
              `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${queryParameters.get('target_id')}`,
              { state: { location }, replace: true },
            )
          } else {
            const phraseItem = response.data.data.find((item) => {
              return +item.id === +queryParameters.get('phrase_id')
            })

            updateHash(
              phraseItem,
              queryParameters.get('area_id'),
              queryParameters.get('phrase_id'),
              queryParameters.get('collation_id'),
              parseCollationIdFromHash(),
            )
          }
          setIsDataLoading(false)
        })
        .catch((error) => {
          // To handle cancel axios error
          if (axios.isCancel(error)) {
            console.debug('Request canceled:', error)
          } else if (error?.response?.request?.status === 404) {
            setSourcePhrases([])
            setIsDataLoading(false)
          }
          if (
            error?.response?.data?.detail &&
            error?.response?.request?.status !== 500 &&
            error?.response?.request?.status !== 502
          ) {
            enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
              variant: 'error',
              persist: true,
            })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
      handleGetSourcePhraseByPhraseId(queryParameters.get('phrase_id'))
    }
    // To render only Source phrase and expand respective Area
    else if (
      projectId &&
      queryParameters.get('area_id') &&
      queryParameters.get('phrase_id')
    ) {
      setExpanded(`searchPhrasePanel${queryParameters.get('area_id')}`)
      setIsDataLoading(true)
      setSourcePhrases([])
      getSourcePhrasesByAreaId(projectId, queryParameters.get('area_id'))
        .then((response) => {
          setSourcePhrases(response.data.data)
          if (response?.data?.data?.length === 0) {
            setSelectedHighlightId(null)
            setSelectedHighlight([])
            document.location.hash = ''
            setShowTargetPdfAndCollation(false)
            queryParameters.get('target_id')
              ? navigate(
                  `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${queryParameters.get('target_id')}`,
                  { state: { location }, replace: true },
                )
              : navigate(
                  `${basePath}?project_id=${queryParameters.get('project_id')}`,
                  { state: { location }, replace: true },
                )
          } else {
            const phraseItem = response.data.data.find((item) => {
              return +item.id === +queryParameters.get('phrase_id')
            })

            updateHash(
              phraseItem,
              queryParameters.get('area_id'),
              queryParameters.get('phrase_id'),
            )
          }
          setIsDataLoading(false)
        })
        .catch((error) => {
          // To handle cancel axios error
          if (axios.isCancel(error)) {
            console.debug('Request canceled:', error)
          } else if (error?.response?.request?.status === 404) {
            setSourcePhrases([])
            setIsDataLoading(false)
          }
          if (
            error?.response?.data?.detail &&
            error?.response?.request?.status !== 500 &&
            error?.response?.request?.status !== 502
          ) {
            enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
              variant: 'error',
              persist: true,
            })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
      handleGetSourcePhraseByPhraseId(queryParameters.get('phrase_id'))
    }
  }, [])

  // TO update source phrase count on Area List change
  useEffect(() => {
    setAreaListLoading(false)
    if (areaList?.length > 0) {
      const totalCount = areaList?.reduce(
        (acc, item) => acc + Number(item.source_phrase_count),
        0,
      )
      setSourceCount(totalCount)
    }
  }, [areaList])

  // This useEffect will run when edit mode change
  useEffect(() => {
    // expand Area if phrase and Area Id present
    if (
      !isPhraseEditMode.isEditMode &&
      isPhraseEditMode.phraseId &&
      isPhraseEditMode.areaId
    ) {
      setExpanded(`searchPhrasePanel${isPhraseEditMode.areaId}`)
      handleGetSourcePhrasesByAreaId(
        isPhraseEditMode.areaId,
        isPhraseEditMode.phraseId,
      )
      handleGetAreaByID(projectId, isPhraseEditMode.areaId)
    }
  }, [isPhraseEditMode])

  // To update Area List Whenever projectStatus change to COLLATION_VERIFICATION
  useEffect(() => {
    if (projectStatus === 'COLLATION_VERIFICATION') {
      handleGetAreaList(projectId)
    }
  }, [projectStatus])

  useEffect(() => {
    if (collationName === '') {
      setAddIconId(null)
    }
  }, [collationName])

  // This function toggles the add/close icon state for an item
  const toggleAddIcon = (itemId, name) => {
    if (addIconId === itemId) {
      // If clicking the same item, reset (close the add icon)
      setAddIconId(null)
      setCollationName('')
    } else {
      // Otherwise, set this item as the active one (show the close icon)
      setAddIconId(itemId)
      setCollationName(name)
    }
  }

  // To get the list of Source Phrases by Area ID
  const handleGetSourcePhrasesByAreaId = (id: string, selelctedId?: any) => {
    setIsDataLoading(true)
    setSourcePhrases([])
    getSourcePhrasesByAreaId(projectId, id, areaQuery)
      .then((response) => {
        setSourcePhrases(response.data.data)
        if (response.data.data.length === 0 && sourcePhrases.length === 1) {
          handleShowDangerTip(id)
        }
        setCollationList(null)
        setSelectedHighlightId(null)
        setSelectedHighlight([])
        // When user expand the area this condition will select first sourcePhrase as default
        if (response?.data?.data?.length > 0 && !selelctedId) {
          targetFiles.length > 0 &&
            handleGetTargetFiles(projectId, '', response.data.data[0].id)
          updateHash(response.data.data[0], id, response.data.data[0].id)
          setSelectedHighlightId(response.data.data[0].id)
          setSelectedHighlight(response.data.data)
          showTargetPdfAndCollation &&
            targetId &&
            response.data.data[0].id &&
            handleGetCollation(response.data.data[0].id, targetId)
        }
        // When there is already selectedPhraseId present
        else if (response?.data?.data?.length > 0 && selelctedId) {
          const area = response.data.data.find((phrase) => {
            return +phrase.id === +selelctedId
          })
          targetFiles.length > 0 &&
            handleGetTargetFiles(projectId, '', selelctedId)
          updateHash(area, id, selelctedId)
          showTargetPdfAndCollation &&
            targetId &&
            response.data.data[0].id &&
            handleGetCollation(selelctedId, targetId)
          highlightAllStates[id]
            ? setSelectedHighlight(response.data.data)
            : setSelectedHighlight([area])
          setSelectedHighlightId(selelctedId)
        }
        // when there no phrases for area
        else {
          document.location.hash = ''
          if (queryParameters.get('target_id')) {
            navigate(
              `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${queryParameters.get('target_id')}&area_id=${id}`,
              { state: { location }, replace: true },
            )
          } else {
            navigate(
              `${basePath}?project_id=${queryParameters.get('project_id')}&area_id=${id}`,
              { state: { location }, replace: true },
            )
          }
        }
        // } else {
        //   setPhraseId(null)
        // }
        setIsDataLoading(false)
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        }
        if (error?.response?.request?.status === 404) {
          setIsDataLoading(false)
          setSourcePhrases([])
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          setIsDataLoading(false)
          enqueueSnackbar(t(`${error?.response?.data?.detail}`), {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          setIsDataLoading(false)
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  // To get source phrase by phrase id
  const handleGetSourcePhraseByPhraseId = (phraseId: any) => {
    getSourcePhraseByPhraseId(projectId, phraseId)
      .then((response) => {
        if (response.status === 200) {
          setSelectedHighlightId(response?.data?.id)
          setSelectedHighlight([response?.data])
          // Update the collation list if showTargetPdfAndCollation(4 split pane view) is true
          showTargetPdfAndCollation &&
            response?.data?.id &&
            handleGetCollation(response?.data?.id, targetId)
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleGetAreaByID = (projectId, areaId: any) => {
    setIsDataLoading(true)
    getAreaById(projectId, areaId)
      .then((response) => {
        if (response.status === 200) {
          const updatedList = areaList.map((item) => {
            if (areaId === item.id) {
              return {
                ...item,
                source_phrases_finalized:
                  response.data.status !== 'NOT_FINALIZED',
                verified_badge: response.data.status !== 'NOT_FINALIZED',
                source_phrase_count: response.data.source_phrase_count,
              }
            }
            return item
          })
          setAreaList(updatedList)
        }
        setIsDataLoading(false)
      })
      .catch((error) => {
        setIsDataLoading(false)
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  // Function to handle deletion of a search phrase by its text
  const handleDeleteArea = (area, confirm?: boolean) => {
    setAreaListLoading(true)
    deleteAreaById(projectId, area.id, confirm)
      .then((res) => {
        if (res.status === 200) {
          handleProjectStatus(projectId)
          handleGetAreaList(projectId)
          document.location.hash = ''
          setSelectedHighlight([])
          setCollationName('')
          setSelectedHighlightId(null)
          setAreaListLoading(false)
          queryParameters.get('target_id')
            ? navigate(
                `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${queryParameters.get('target_id')}`,
                { state: { location }, replace: true },
              )
            : navigate(
                `${basePath}?project_id=${queryParameters.get('project_id')}`,
                { state: { location }, replace: true },
              )
        }
        // Show the confirmation dialog when user click delete Area button
        if (res.status === 202) {
          if (res.data.confirmation_required) {
            handleOpenReplaceConfirmDialog(
              'delete_area',
              area,
              null,
              res.data.detail,
            )
          } else {
            setAreaListLoading(false)
          }
        }
      })
      .catch((error) => {
        setAreaListLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  // Function to handle deletion of a highlight by its ID
  const handleDeletePhrase = (itemId, phraseId) => {
    setIsDataLoading(true)
    deleteSourcePhraseByPhraseId(projectId, phraseId)
      .then((res) => {
        if (res.status === 200) {
          handleProjectStatus(projectId)
          if (phraseId === selectedHighlightId) {
            setSelectedHighlightId(null)
            setSelectedHighlight([])
            document.location.hash = ''
          }
          handleGetSourcePhrasesByAreaId(itemId)
          handleGetAreaByID(projectId, itemId)
          setCollationName('')
          queryParameters.get('target_id')
            ? navigate(
                `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${queryParameters.get('target_id')}&area_id=${queryParameters.get('area_id')}`,
                { state: { location }, replace: true },
              )
            : navigate(
                `${basePath}?project_id=${queryParameters.get('project_id')}&area_id=${queryParameters.get('area_id')}`,
                { state: { location }, replace: true },
              )
        }
      })
      .catch((error) => {
        setIsDataLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  // Function to handle finalizing source phrase
  const handleFinalizePhrase = (item, confirm?: boolean) => {
    setIsDataLoading(true)
    finalizeAreaByAreaId(projectId, item.id, confirm)
      .then((res: any) => {
        if (res.status === 200) {
          handleProjectStatus(projectId)
          handleGetSourcePhrasesByAreaId(item.id)
          handleGetAreaList(projectId)
        }
        if (res.status === 202) {
          if (res.data.confirmation_required) {
            handleOpenReplaceConfirmDialog(
              'finalize_area',
              item,
              null,
              res.data.detail,
            )
          } else {
            setIsDataLoading(false)
          }
        }
      })
      .catch((error) => {
        setIsDataLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  // Function to handle verify source phrase
  const handleVerifyPhrase = (itemId) => {
    setIsDataLoading(true)
    finalizeVerificationAreaByAreaId(projectId, itemId)
      .then((res) => {
        if (res.status === 200) {
          handleProjectStatus(projectId)
          handleGetSourcePhrasesByAreaId(itemId)
          handleGetAreaList(projectId)
        }
      })
      .catch((error) => {
        setIsDataLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const [isVerificationDialogOpen, setVerificationDialogOpen] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState(null)

  const openVerificationDialog = (e, itemId, treasureCount) => {
    e.stopPropagation()
    hideSelection()

    if (treasureCount > 0) {
      setVerificationDialogOpen(true)
      setSelectedItemId(itemId)
    } else {
      handleVerifyPhrase(itemId)
    }
  }
  const confirmVerification = () => {
    if (selectedItemId !== null) {
      handleVerifyPhrase(selectedItemId)
    }
    setVerificationDialogOpen(false)
    setSelectedItemId(null)
  }

  const cancelVerification = () => {
    setVerificationDialogOpen(false)
    setSelectedItemId(null)
  }

  const handleOpenConfirmDialog = () => {
    setVerificationDialogOpen(true)
  }
  const handleCloseVerificationConfirmDialog = (result?: string) => {
    console.log(result)
    if (result === 'confirm') {
      confirmVerification()
    } else {
      cancelVerification()
    }
  }
  const handleChange =
    (panel: string, itemId?: any) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSelectedCollation([])
      setCollationList(null)
      setSelectedHighlightId(null)
      setSelectedHighlight([])
      if (isExpanded && itemId) {
        handleGetSourcePhrasesByAreaId(itemId)
        if (queryParameters.get('target_id') && itemId) {
          navigate(
            `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${queryParameters.get('target_id')}&area_id=${itemId}`,
            { state: { location }, replace: true },
          )
        } else {
          navigate(
            `${basePath}?project_id=${queryParameters.get('project_id')}&area_id=${itemId}`,
            { state: { location }, replace: true },
          )
        }
        // Initialize highlight state for the item if not already initialized
      } else {
        setPhraseId(null)
        document.location.hash = ''
        setCollationList(null)
        setSelectedHighlightId('')
        setSelectedHighlight([])
        setSourcePhrases([])
        targetFiles.length > 0 && handleGetTargetFiles(projectId, '')
        // Find the corresponding area object to check its expected number of source phrases.
        const area = areaList.find((area) => area.id === itemId)
        if (area && sourcePhrases?.length !== area.source_phrase_count) {
          // If the number of source phrases doesn't match the count in areaList, fetch area list again.
          setAreaListLoading(true)

          handleGetAreaList(projectId)
        }
      }
      setEditPhraseModeId(null)
      setIsPhraseEditMode({
        isEditMode: false,
        areaId: null,
        phraseId: null,
        phrases: [],
      })

      setAddIconId(null)
      setCollationName('')
      setSourcePhrases([])
      setExpanded(isExpanded ? panel : '')
      queryParameters.get('target_id')
        ? navigate(
            `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${queryParameters.get('target_id')}`,
            { state: { location }, replace: true },
          )
        : navigate(
            `${basePath}?project_id=${queryParameters.get('project_id')}`,
            { state: { location }, replace: true },
          )
    }

  const handleClick = (event, itemId) => {
    // Set the item ID to identify which popover is active
    setAnchorEl(event.currentTarget)
    setActivePopoverId(itemId)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setActivePopoverId(null) // Reset active popover ID
  }

  const handleEdit = (newName, item) => {
    setAreaListLoading(true)
    updateAreaName(projectId, { name: newName }, item.id)
      .then((res) => {
        if (res.status === 200) {
          handleGetAreaList(projectId)
          setAreaListLoading(false)
          setIsDataLoading(false)
          enqueueSnackbar(t(`Area name successfully changed`), {
            variant: 'success',
            transitionDuration: { enter: 300, exit: 400 },
            autoHideDuration: 2,
            persist: true,
          })
        }
      })
      .catch((error) => {
        setAreaListLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleGetAreaListWithQuery = (query) => {
    setAreaListLoading(true)
    setIsAreaSearched(false)
    getAreaList(projectId, query)
      .then((res) => {
        if (res.status === 200) {
          if (query.length > 0) {
            setIsAreaSearched(true)
            queryParameters.get('area_id') &&
              handleGetSourcePhrasesByAreaId(queryParameters.get('area_id'))
          }
          setAreaList(res.data)
          setAreaListLoading(false)
        }
      })
      .catch((error) => {
        setAreaListLoading(false)
        setIsAreaSearched(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  // Converting given date format to browser date format
  const formatDateToLocalTime = (utcDateString) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const date = moment(utcDateString)
    const formattedDate = date.format('YYYY/MM/DD')
    const formattedTime = date.format('HH:mm')
    return `${formattedDate} ${formattedTime}`
  }
  // Opens a "Backgrowd prcrss again message "
  const handleOpenReplaceConfirmDialog = (
    from,
    area,
    phrase?: any,
    msg?: any,
  ) => {
    if (phrase) {
      setDialogState({
        isOpen: true,
        area,
        phrase,
        type: from,
        msg,
      })
    } else {
      setDialogState({
        isOpen: true,
        area,
        phrase: null,
        type: from,
        msg,
      })
    }
  }

  const handlCloseCancelDeleteConfirmDialog = (result: string) => {
    // run this if user click on Confirm button
    if (result === 'confirm') {
      if (dialogState?.area && dialogState?.isOpen && dialogState?.type) {
        if (dialogState?.type === 'delete_phrase') {
          handleDeletePhrase(dialogState?.area?.id, dialogState?.phrase?.id)
        } else if (dialogState?.type === 'update') {
          hideSelection()
          const selection: any = window.getSelection()
          selection.rangeCount > 0 && selection.removeAllRanges()
          setAddIconId(null)
          setCollationName(dialogState?.area?.name)
          setEditPhraseModeId(dialogState?.phrase?.id)
          setSelectedHighlightId(null)
        } else if (dialogState.type === 'delete_area') {
          handleDeleteArea(dialogState?.area, true)
        } else {
          handleFinalizePhrase(dialogState?.area, true)
        }
      }
    }
    // run this if user click on cancel button
    else {
      setIsDataLoading(false)
      setAreaListLoading(false)
      // highlight phrase back which user wanted to edit but abort cancellation
      if (dialogState?.type === 'update') {
        setSelectedHighlight(
          sourcePhrases.filter((item) => item.id === dialogState?.phrase?.id),
        )
      }
      // turn of edit phrase mode
      setIsPhraseEditMode({
        isEditMode: false,
        areaId: null,
        phraseId: null,
        phrases: [],
      })
    }
    setDialogState({ isOpen: false, collation: {}, type: null }) // Reset dialog state
  }
  // Hiding the pdf text selection
  const hideSelection = () => {
    const selection: any = window.getSelection()
    selection.rangeCount > 0 && selection.removeAllRanges()
  }
  // To acknowledge phrase after source document uodate
  const handleAcknowledge = (itemId, id) => {
    acknowledgePhrase(projectId, id)
      .then((res) => {
        handleGetSourcePhrasesByAreaId(itemId)
      })
      .catch((error) => {
        setAreaListLoading(false)
        setIsAreaSearched(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  return (
    <Stack mb={clickedArea && dangerTipSatate?.[clickedArea] ? '70px' : '24px'}>
      {(sourceCount > 0 || isAreaSearched) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            gap: 1,
          }}
        >
          <TextField
            id="search-projects"
            placeholder={t('Search Source Phrases')}
            variant="outlined"
            size="small"
            value={areaQuery}
            style={{
              flex: 1,
            }}
            disabled={
              projectStatus === 'COLLATION_IN_PROGRESS' ||
              projectStatus === 'PREPARING_FOR_COLLATION' ||
              projectStatus === 'INHERITING_COLLATIONS' ||
              !isProgressComplete ||
              isManualCollationInProgress
            }
            onChange={(e) => {
              if (e.target.value === '') {
                setIsAreaSearched(false)
              }
              setAreaQuery(e.target.value)
            }}
            sx={{
              flex: 1,
              '& .MuiInputBase-input': {
                color: 'black', // Change text color
              },
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleGetAreaListWithQuery(areaQuery)
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: 'pointer' }}
                  //  disable clickevent based on conditions below
                  disablePointerEvents={
                    projectStatus === 'COLLATION_IN_PROGRESS' ||
                    projectStatus === 'PREPARING_FOR_COLLATION' ||
                    projectStatus === 'INHERITING_COLLATIONS' ||
                    !isProgressComplete ||
                    isManualCollationInProgress
                  }
                >
                  {areaListLoading ? (
                    <CircularProgress size={20} />
                  ) : areaQuery?.length > 0 && isAreaSearched ? (
                    <span className="icon-wrapper">
                      <img
                        src={closeIcon}
                        className="default-icon"
                        alt="cancel"
                        width={20}
                        height={20}
                        style={{
                          //  disable clickevent based on conditions below
                          pointerEvents:
                            projectStatus === 'COLLATION_IN_PROGRESS' ||
                            projectStatus === 'PREPARING_FOR_COLLATION' ||
                            projectStatus === 'INHERITING_COLLATIONS' ||
                            !isProgressComplete ||
                            isManualCollationInProgress
                              ? 'none'
                              : 'auto',
                        }}
                        onClick={(e) => {
                          setIsAreaSearched(false)
                          setAreaQuery('')
                          handleGetAreaListWithQuery('')
                        }}
                      />
                      <img
                        src={ActiveCloseIcon}
                        className="active-icon"
                        alt="cancel"
                        width={20}
                        height={20}
                        style={{
                          //  disable clickevent based on conditions below
                          pointerEvents:
                            projectStatus === 'COLLATION_IN_PROGRESS' ||
                            projectStatus === 'PREPARING_FOR_COLLATION' ||
                            projectStatus === 'INHERITING_COLLATIONS' ||
                            !isProgressComplete ||
                            isManualCollationInProgress
                              ? 'none'
                              : 'auto',
                        }}
                        onClick={(e) => {
                          setIsAreaSearched(false)
                          setAreaQuery('')
                          handleGetAreaListWithQuery('')
                        }}
                      />
                    </span>
                  ) : (
                    <MagniferIcon
                      onClick={() => {
                        handleGetAreaListWithQuery(areaQuery)
                      }}
                    />
                  )}
                </InputAdornment>
              ),
              style: {
                borderRadius: '4px',
              },
            }}
          />
          {/* <Box>
            <Tooltip title="Filter options">
              <IconButton
                onClick={handleFilterClick}
                aria-controls={openFilter ? 'filter-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openFilter ? 'true' : undefined}
                style={{
                  border: '1px solid var(--gray-200)',
                  borderRadius: '4px',
                }}
              >
                {filterAnchorEl ? <FilledFilterIcon /> : <FilterIcon />}
              </IconButton>
            </Tooltip>
            <Menu
              id="filter-menu"
              anchorEl={filterAnchorEl}
              open={Boolean(filterAnchorEl)}
              onClose={handleFilterClose}
              keepMounted
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  borderRadius: '8px',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <MenuItem
                key="select-all"
                onClick={() => {
                  handleMenuItemClick('Select All')
                }}
              >
                <Checkbox
                  checkedIcon={<CheckedIcon />}
                  icon={<CheckBoxIcon />}
                  checked={
                    isAllSelected || selectedFilters.length === areaList.length
                  }
                />
                <ListItemText primary="Select All" />
              </MenuItem>
              {areaList.length > 0 &&
                areaList?.map((option) => (
                  <MenuItem
                    key={option.id}
                    onClick={() => {
                      handleMenuItemClick(option.name)
                    }}
                    selected={selectedFilters.indexOf(option.name) > -1}
                  >
                    <Checkbox
                      checkedIcon={<CheckedIcon />}
                      icon={<CheckBoxIcon />}
                      checked={selectedFilters.indexOf(option.name) > -1}
                    />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
            </Menu>
          </Box> */}
        </Box>
      )}
      {!areaListLoading && areaList?.length === 0 && (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          {t('No Source Phrases')}
        </Box>
      )}

      {areaListLoading ? (
        <div style={{ paddingTop: '2rem' }}>
          <CircularProgress />
        </div>
      ) : (
        areaList?.length > 0 &&
        areaList?.map((item, index) => (
          <Accordion
            className="search-accordion"
            style={{
              margin: '10px 0',
              border:
                item.source_phrase_count === 0
                  ? '1px solid var(--red-600)'
                  : highlightAllStates[item.id]
                    ? '1px solid var(--primary-600)'
                    : '1px solid var(--gray-200)',
              borderRadius: '4px',
              pointerEvents:
                projectStatus === 'COLLATION_IN_PROGRESS' ||
                projectStatus === 'PREPARING_FOR_COLLATION' ||
                projectStatus === 'INHERITING_COLLATIONS' ||
                !isProgressComplete ||
                isManualCollationInProgress
                  ? 'none'
                  : 'auto',
            }}
            key={item.id}
            // Ensure the expanded prop is always controlled by passing a boolean
            expanded={expanded === `searchPhrasePanel${item.id}`}
            onChange={handleChange(`searchPhrasePanel${item.id}`, item.id)}
          >
            <AccordionSummary
              sx={{
                '&.Mui-focusVisible': {
                  backgroundColor: '#fff !important',
                },
                '& .MuiAccordionSummary-content': {
                  gap: '10px !important',
                },
              }}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                position: 'relative',
                gap: '10px',
              }}
              onClick={(e: any) => {
                e.stopPropagation()
                hideSelection()
              }}
            >
              <Stack
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                direction={'row'}
                width={'100%'}
                className="accordian-input-wrapper webkit-fill-available moz-available fill-available"
                style={{ overflow: 'hidden' }}
              >
                {/* Area name edit mode view */}
                {editingItemId === item.id ? (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    type="text"
                    fullWidth
                    variant="standard"
                    style={{
                      pointerEvents:
                        projectStatus === 'COLLATION_IN_PROGRESS' ||
                        projectStatus === 'PREPARING_FOR_COLLATION' ||
                        projectStatus === 'INHERITING_COLLATIONS' ||
                        !isProgressComplete ||
                        isManualCollationInProgress
                          ? 'none'
                          : 'auto',
                    }}
                    placeholder={t('Enter Area Name', {
                      context: 'Enter collation area name',
                    })}
                    value={tempItemName}
                    onChange={(e) => {
                      // e.target.value.trim()
                      setTempItemName(e.target.value)
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    sx={{
                      '& .MuiInput-underline': {
                        color: 'var(--gray-600) !important',
                      },
                      '& .MuiInput-root': {
                        height: '20px !important',
                        padding: '0  0 5px 0 !important',
                        marginTop: '-4px',
                      },
                    }}
                    onBlur={(e) => {
                      const relatedTargetId = e.relatedTarget?.id
                      // Check if the relatedTargetId is one of the ids to be excluded
                      if (
                        relatedTargetId === `editnametick${item.id}` ||
                        relatedTargetId === `editnamecancel${item.id}`
                      ) {
                        // Do nothing if the blur event is from one of the excluded IDs
                        return
                      }
                      setEditingItemId(null) // Exit edit mode
                      setTempItemName('') // Clear temporary name
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Tab') {
                        if (item.name !== tempItemName) {
                          handleEdit(tempItemName, item) // Save changes
                        }
                        setEditingItemId(null) // Exit edit mode
                        setTempItemName('') // Clear temporary name
                      }
                      if (e.key === 'Escape') {
                        setEditingItemId(null) // Exit edit mode
                        setTempItemName('') // Initialize with current name
                      }
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        if (item.name !== tempItemName) {
                          handleEdit(tempItemName, item) // Save changes
                        }
                        setEditingItemId(null) // Exit edit mode
                        setTempItemName('') // Clear temporary name
                      }
                    }}
                  />
                ) : (
                  // Normal Area name view with verified or finalize badge icon
                  <Box
                    display={'flex'}
                    alignItems={'end'}
                    textAlign={'left'}
                    gap={1}
                    mr={'5px'}
                    style={{
                      textOverflow: 'ellipsis',
                      textWrap: 'nowrap',
                      overflow: 'hidden',
                      width: '100%',
                      maxHeight: 'max-content',
                    }}
                  >
                    <Tooltip title={item?.name}>
                      <Typography
                        fontWeight={600}
                        style={{
                          textOverflow: 'ellipsis',
                          textWrap: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {item?.name ? item?.name : '-'}
                      </Typography>
                    </Tooltip>
                    <Box mr={2}>
                      {!item.verified_badge &&
                        item.source_phrases_finalized && (
                          <Tooltip title={'Finalized'}>
                            <CircleCheckIcon />
                          </Tooltip>
                        )}{' '}
                      {item.verified_badge && (
                        <Tooltip title={'Verified'}>
                          <CircleFilledCheckIcon />
                        </Tooltip>
                      )}
                      {item.source_phrase_count === 0 && (
                        <>
                          {dangerTipSatate[item.id] ? (
                            <DangerActiveIcon
                              style={{ pointerEvents: 'auto' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleHideDangerTip(item.id)
                              }}
                            />
                          ) : (
                            <DangerIcon
                              style={{ pointerEvents: 'auto' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleShowDangerTip(item.id)
                              }}
                            />
                          )}
                          <Box
                            ref={boxRef}
                            sx={{
                              display: dangerTipSatate[item.id]
                                ? 'flex'
                                : 'none',
                              position: 'absolute',
                              width: '76%',
                              height: 'fit-content',
                              background: 'var(--red-50)',
                              color: 'var(--red-500)',
                              top: '40px',
                              left: '32px',
                              flexDirection: 'column',
                              whiteSpace: 'wrap',
                              zIndex: '1',
                              padding: '1rem',
                              borderRadius: '8px',
                              // '&::before': {
                              //   content: '""',
                              //   display: 'block',
                              //   position: 'absolute',
                              //   top: 0,
                              //   left: 10,
                              //   width: 10,
                              //   height: 10,
                              //   bgcolor: 'var(--red-50)',
                              //   transform: 'translateY(-50%) rotate(45deg)',
                              //   zIndex: 0,
                              // },
                            }}
                          >
                            <Typography fontWeight={500} fontSize={'12px'}>
                              {t('Urgent action required')}
                            </Typography>
                            <Typography fontWeight={400} fontSize={'12px'}>
                              {t(
                                'Please delete this area as it lacks source phrases, preventing the project from moving to completed status.',
                              )}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                )}
                <Box display="flex" gap={1.5} alignItems="center">
                  {editingItemId === item.id && (
                    <span
                      style={{ display: 'flex', margin: '0 7px', gap: '5px' }}
                    >
                      <img
                        src={checkIcon}
                        alt="confirm"
                        tabIndex={0}
                        id={`editnametick${item.id}`}
                        style={{
                          //  disable clickevent based on conditions below
                          pointerEvents:
                            projectStatus === 'COLLATION_IN_PROGRESS' ||
                            projectStatus === 'PREPARING_FOR_COLLATION' ||
                            projectStatus === 'INHERITING_COLLATIONS' ||
                            !isProgressComplete ||
                            isManualCollationInProgress
                              ? 'none'
                              : 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          hideSelection()

                          if (item.name !== tempItemName) {
                            handleEdit(tempItemName, item) // Save changes
                          }
                          setEditingItemId(null) // Exit edit mode
                          setTempItemName('') // Clear temporary name
                        }}
                      />
                      <img
                        src={closeIcon}
                        alt="cancel"
                        id={`editnamecancel${item.id}`}
                        style={{
                          //  disable clickevent based on conditions below
                          pointerEvents:
                            projectStatus === 'COLLATION_IN_PROGRESS' ||
                            projectStatus === 'PREPARING_FOR_COLLATION' ||
                            projectStatus === 'INHERITING_COLLATIONS' ||
                            !isProgressComplete ||
                            isManualCollationInProgress
                              ? 'none'
                              : 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          hideSelection()
                          setEditingItemId(null) // Exit edit mode
                          setTempItemName('') // Initialize with current name
                        }}
                      />
                    </span>
                  )}
                  {item.treasure_count > 0 && (
                    <LightTooltip
                      title={`${item.treasure_count} treasures`}
                      placement="bottom-end"
                      // sx={{ zIndex: 1301 }}
                    >
                      <span>
                        <Badge
                          badgeContent={item.treasure_count}
                          // color="error"
                          sx={{
                            marginRight: '15px',
                            marginBottom: '3px',
                            '& .MuiBadge-badge': {
                              backgroundColor: '#ffecb3',
                              color: '#795548',
                            },
                          }}
                        ></Badge>
                      </span>
                    </LightTooltip>
                  )}
                  {editingItemId !== item.id && (
                    <img
                      src={editIcon}
                      alt="Edit"
                      style={{
                        pointerEvents:
                          projectStatus === 'COLLATION_IN_PROGRESS' ||
                          projectStatus === 'PREPARING_FOR_COLLATION' ||
                          projectStatus === 'INHERITING_COLLATIONS' ||
                          !isProgressComplete ||
                          isManualCollationInProgress
                            ? 'none'
                            : 'auto',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        hideSelection()
                        setEditingItemId(item.id)
                        setTempItemName(item.name) // Initialize with current name
                      }}
                    />
                  )}
                  <img
                    src={
                      addIconId === item.id ? filledCircleCloseIcon : addIcon
                    }
                    alt="Add"
                    style={{
                      pointerEvents:
                        projectStatus === 'COLLATION_IN_PROGRESS' ||
                        projectStatus === 'PREPARING_FOR_COLLATION' ||
                        projectStatus === 'INHERITING_COLLATIONS' ||
                        !isProgressComplete ||
                        isManualCollationInProgress
                          ? 'none'
                          : 'auto',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      hideSelection()
                      setEditPhraseModeId(null)
                      setCollationName('')
                      setIsPhraseEditMode({
                        isEditMode: false,
                        areaId: null,
                        phraseId: null,
                        phrases: [],
                      })
                      toggleAddIcon(item.id, item.name) // Toggle the icon for this item
                      setIsPhraseAddMode({
                        isAddMode: false,
                        areaId: null,
                        phraseId: null,
                        phrases: [],
                      })
                      if (addIconId !== item.id) {
                        const selection: any = window.getSelection()
                        selection.rangeCount > 0 && selection.removeAllRanges()
                        setIsPhraseAddMode({
                          isAddMode: true,
                          areaId: item.id,
                          phraseId: selectedHighlightId,
                          phrases:
                            selectedHighlight && selectedHighlight.length > 0
                              ? selectedHighlight
                              : [],
                        })
                        setSelectedHighlightId('')
                        setSelectedHighlight([])
                        document.location.hash = ''
                      }
                    }}
                  />

                  <img
                    src={kebabIcon}
                    alt="menu"
                    aria-describedby={id}
                    style={{
                      pointerEvents:
                        projectStatus === 'COLLATION_IN_PROGRESS' ||
                        projectStatus === 'PREPARING_FOR_COLLATION' ||
                        projectStatus === 'INHERITING_COLLATIONS' ||
                        !isProgressComplete ||
                        isManualCollationInProgress
                          ? 'none'
                          : 'auto',
                      cursor: 'pointer',
                    }}
                    onClick={(e: any) => {
                      e.stopPropagation()
                      hideSelection()
                      // setSelectedHighlight(item)
                      handleClick(e, item.id) // Pass item ID here
                      setInteractedItemId(item.id)
                    }}
                  />
                </Box>
                <Popover
                  open={activePopoverId === item.id}
                  id={`${id}${item.id}`}
                  style={{ borderRadius: '8px', boxShadow: 'none' }}
                  anchorEl={anchorEl}
                  onClose={(e: any) => {
                    e.stopPropagation() // Stop event propagation
                    handleClose() // Then, close the popover
                    // setSelectedHighlight(null)
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <Box
                    className="popover-content" // Add this class to target children with CSS
                    onClick={(e: any) => {
                      e.stopPropagation()
                      hideSelection()
                    }}
                    p={2}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    color={'var(--gray-600'}
                  >
                    {/* Stop propagation for clicks within the Popover */}
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        // Your code to delete (if applicable)
                        handleDeleteArea(item)
                        hideSelection()
                        handleClose() // Close the popover after deletion
                      }}
                    >
                      {t('Delete')}
                    </div>
                    <div
                      style={{
                        cursor: 'pointer',
                        pointerEvents:
                          projectStatus === 'COLLATION_IN_PROGRESS' ||
                          projectStatus === 'PREPARING_FOR_COLLATION' ||
                          projectStatus === 'INHERITING_COLLATIONS' ||
                          !isProgressComplete ||
                          isManualCollationInProgress
                            ? 'none'
                            : 'auto',
                      }}
                      className={
                        interactedItemId === item.id &&
                        item?.source_phrase_count > 1
                          ? ''
                          : 'disabled'
                      } // Conditionally add 'disabled' class
                      onClick={(e) => {
                        // e.stopPropagation()
                        handleChange(`searchPhrasePanel${item.id}`)
                        hideSelection()
                        setHighlightAllStates((prevState) => ({
                          ...prevState,
                          [item.id]: true,
                        }))

                        sourcePhrases?.length > 0 &&
                          setSelectedHighlight(sourcePhrases)
                        handleClose() // Close the popover after selection
                      }}
                    >
                      {!highlightAllStates[item.id] &&
                        `searchPhrasePanel${item.id}` &&
                        t('Highlight All')}
                    </div>
                  </Box>
                </Popover>
              </Stack>
              <Box
                className={'correct'}
                textAlign={'left'}
                display={'flex'}
                justifyContent={'space-between'}
                width={'100%'}
                alignItems={'center'}
              >
                {/* Source phrases count View start */}
                <Typography>
                  {t('Source Phrases:')}{' '}
                  {/* {expanded === `searchPhrasePanel${item.id}` ? (
                    <b>
                      {sourcePhrases?.length < 10 && sourcePhrases?.length !== 0
                        ? `0${sourcePhrases?.length}`
                        : sourcePhrases?.length === 0
                          ? '--'
                          : sourcePhrases?.length}
                    </b>
                  ) : ( */}
                  <b>
                    {item.source_phrase_count < 10 &&
                    item.source_phrase_count !== 0
                      ? `0${item.source_phrase_count}`
                      : item.source_phrase_count === 0
                        ? '00'
                        : item.source_phrase_count}
                  </b>
                  {/* )} */}
                </Typography>
                {/* Source phrases count View end */}
                {/* Accordion expand and collapse button view start */}
                <Box mr={'2px'}>
                  {expanded === `searchPhrasePanel${item.id}` ? (
                    <ArrowUpIcon
                      style={{
                        pointerEvents:
                          projectStatus === 'COLLATION_IN_PROGRESS' ||
                          projectStatus === 'PREPARING_FOR_COLLATION' ||
                          projectStatus === 'INHERITING_COLLATIONS' ||
                          !isProgressComplete ||
                          isManualCollationInProgress
                            ? 'none'
                            : 'auto',
                      }}
                      onClick={() => {
                        setSelectedHighlight([])
                        hideSelection()
                        handleChange(`searchPhrasePanel${item.id}`)
                      }}
                    />
                  ) : (
                    <ArrowDownIcon
                      style={{
                        pointerEvents:
                          projectStatus === 'COLLATION_IN_PROGRESS' ||
                          projectStatus === 'PREPARING_FOR_COLLATION' ||
                          projectStatus === 'INHERITING_COLLATIONS' ||
                          !isProgressComplete ||
                          isManualCollationInProgress
                            ? 'none'
                            : 'auto',
                      }}
                      onClick={() => {
                        setIsDataLoading(true)
                        hideSelection()
                        handleChange(`searchPhrasePanel${item.id}`)
                      }}
                    />
                  )}
                </Box>
                {/* Accordion expand and collapse button view end */}
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                pointerEvents: 'none',
              }}
            >
              {!isDataLoading ? (
                <>
                  {/* Status of phrases:- NORMAL, INHERITED, PAGE_CHANGED, FOUND, NOT_FOUND */}
                  {sourcePhrases?.length === 0 && expanded ? (
                    <span>{t('No source phrases found')}</span>
                  ) : (
                    sourcePhrases?.map((phrase: any) => {
                      const phraseContent = (
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          bgcolor={
                            selectedHighlightId === phrase.id &&
                            phrase.search_phrase_status === 'NOT_FOUND'
                              ? 'var(--red-400)'
                              : selectedHighlightId === phrase.id ||
                                  editPhraseModeId === phrase.id
                                ? 'var(--gray-100)'
                                : '#fff'
                          }
                          border={
                            selectedHighlightId === phrase.id
                              ? '1px solid var(--primary-600) !important'
                              : phrase.search_phrase_status ===
                                    'PAGE_CHANGED' ||
                                  phrase.search_phrase_status === 'FOUND'
                                ? '1px solid var(--yellow-500) !important'
                                : phrase.search_phrase_status === 'NOT_FOUND'
                                  ? '1px solid var(--red-600) !important'
                                  : '1px solid var(--gray-200) !important'
                          }
                          color={
                            phrase.search_phrase_status === 'PAGE_CHANGED' ||
                            phrase.search_phrase_status === 'FOUND'
                              ? 'var(--yellow-500)'
                              : phrase.search_phrase_status === 'NOT_FOUND'
                                ? 'var(--red-600)'
                                : '#000'
                          }
                          onClick={(event) => {
                            event.stopPropagation()
                            hideSelection()
                            setEditPhraseModeId(null)
                            // To deselect phrase
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            if (
                              selectedHighlightId === phrase.id ||
                              editPhraseModeId === phrase.id
                            ) {
                              createOrReuseController(
                                `getCollation-${projectId}-${targetId}`,
                              )
                              if (queryParameters.get('target_id')) {
                                navigate(
                                  `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${queryParameters.get('target_id')}&area_id=${queryParameters.get('area_id')}`,
                                  { state: { location }, replace: true },
                                )
                              } else {
                                navigate(
                                  `${basePath}?project_id=${queryParameters.get('project_id')}&area_id=${queryParameters.get('area_id')}`,
                                  { state: { location }, replace: true },
                                )
                              }
                              highlightAllStates[item.id]
                                ? setSelectedHighlight(sourcePhrases)
                                : setSelectedHighlight([])
                              setSelectedHighlightId('')
                              document.location.hash = ''
                              setCollationList(null)
                              targetFiles.length > 0 &&
                                handleGetTargetFiles(projectId, '')
                              setSelectedCollation([])
                              setPhraseId(null)
                            }
                            // To select and highlight phrase
                            else {
                              setCollationList(null)
                              setSelectedCollation([])
                              updateHash(
                                phrase,
                                queryParameters.get('area_id')
                                  ? queryParameters.get('area_id')
                                  : item.id,
                                phrase.id,
                              )
                              targetFiles.length > 0 &&
                                handleGetTargetFiles(projectId, '', phrase.id)

                              setIsPhraseEditMode({
                                isEditMode: false,
                                areaId: null,
                                phraseId: null,
                                phrases: [],
                              })
                              highlightAllStates[item.id]
                                ? setSelectedHighlight(sourcePhrases)
                                : setSelectedHighlight([phrase])
                              setSelectedHighlightId(phrase.id)
                              showTargetPdfAndCollation &&
                                phrase.id &&
                                handleGetCollation(phrase.id, targetId)
                            }
                          }}
                          style={{
                            textAlign: 'left',
                            fontSize: '14px',
                            border: '1px solid var(--gray-200)',
                            borderRadius: '4px',
                            boxShadow: 'none',
                            margin: '4px 0',
                            pointerEvents:
                              projectStatus === 'COLLATION_IN_PROGRESS' ||
                              projectStatus === 'PREPARING_FOR_COLLATION' ||
                              projectStatus === 'INHERITING_COLLATIONS' ||
                              !isProgressComplete ||
                              isManualCollationInProgress
                                ? 'none'
                                : 'auto',
                          }}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'12px'}
                            width={'100%'}
                          >
                            {/* Phrase text UI start */}
                            <LightTooltip
                              title={phrase.text}
                              placement="top-start"
                            >
                              <Typography
                                style={{
                                  cursor:
                                    phrase.search_phrase_status !== 'NOT_FOUND'
                                      ? 'pointer'
                                      : 'auto',
                                  margin: '8px',
                                  color:
                                    phrase.search_phrase_status ===
                                      'PAGE_CHANGED' ||
                                    phrase.search_phrase_status === 'FOUND'
                                      ? 'var(--yellow-500)'
                                      : phrase.search_phrase_status ===
                                          'NOT_FOUND'
                                        ? 'var(--red-600)'
                                        : '#000',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 1, // Number of lines to show
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width:
                                    phrase.search_phrase_status === 'INHERITED'
                                      ? '80%'
                                      : '90%', // Adjust the width as needed
                                }}
                              >
                                {phrase?.text}
                              </Typography>
                            </LightTooltip>
                            {/* Phrase text UI end */}

                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              mr={1}
                              spacing={1.5}
                              // gap={'12px'}
                              // display={'flex'}
                            >
                              {/* Info tooltip to display page change message */}
                              {phrase.search_phrase_status_message &&
                                phrase.has_many_instances === false && (
                                  <LightTooltip
                                    key={phrase.id}
                                    style={{ display: 'block' }}
                                    placement="right"
                                    arrow
                                    title={
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            phrase.search_phrase_status_message,
                                        }}
                                      />
                                    }
                                  >
                                    <img
                                      src={infoCircleIcon}
                                      alt="Info Circle Icon"
                                      style={{
                                        cursor: 'pointer',
                                        width: '20px',
                                        height: '20px',
                                        marginBottom: '3px',
                                      }}
                                    />
                                  </LightTooltip>
                                )}
                              {phrase.search_phrase_status_message &&
                                phrase.has_many_instances === true && (
                                  <LightTooltip
                                    key={phrase.id}
                                    style={{ display: 'block' }}
                                    placement="right" // ✅ Tooltip now appears on the right
                                    arrow
                                    title={
                                      <Stack alignItems="end" p="5px" gap={1}>
                                        {/* Tooltip Content */}
                                        <Typography
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              phrase.search_phrase_status_message,
                                          }}
                                        />

                                        {/* Confirmation Button */}
                                        <Button
                                          sx={{
                                            background: 'var(--primary-600)',
                                            color: 'var(--gray-white)',
                                            height: '30px',
                                            margin: '5px',
                                            borderRadius: '4px',
                                            textTransform: 'capitalize',
                                            '&:hover': {
                                              backgroundColor:
                                                'var(--primary-600)',
                                              opacity: 0.9,
                                            },
                                          }}
                                          onClick={() => {
                                            handleAcknowledge(
                                              item.id,
                                              phrase.id,
                                            )
                                          }}
                                        >
                                          {t('Confirm Source Phrase')}
                                        </Button>
                                      </Stack>
                                    }
                                  >
                                    <img
                                      src={infoCircleIcon}
                                      alt="Info Icon"
                                      style={{
                                        cursor: 'pointer',
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '8px',
                                        marginBottom: '3px',
                                      }}
                                    />
                                  </LightTooltip>
                                )}

                              {phrase.treasure_count > 0 && (
                                <LightTooltip
                                  title={`${phrase.treasure_count} treasures`}
                                  placement="bottom"
                                  // sx={{ zIndex: 1301 }}
                                >
                                  <span>
                                    <Badge
                                      badgeContent={phrase.treasure_count}
                                      // color="error"
                                      sx={{
                                        marginRight: '15px',
                                        marginBottom: '3px',
                                        '& .MuiBadge-badge': {
                                          backgroundColor: '#ffecb3',
                                          color: '#795548',
                                        },
                                      }}
                                    ></Badge>
                                  </span>
                                </LightTooltip>
                              )}
                              {/* Verified collation count UI start */}
                              {phrase.count_of_verified_collations && (
                                <LightTooltip
                                  title={`${phrase.count_of_verified_collations} verified collations`}
                                  placement="bottom"
                                >
                                  <span>
                                    <Badge
                                      badgeContent={
                                        phrase.count_of_verified_collations
                                      }
                                      color="secondary"
                                      style={{
                                        marginRight: '15px',
                                        marginBottom: '3px',
                                      }}
                                    ></Badge>
                                  </span>
                                </LightTooltip>
                              )}
                              {/* Verified collation count UI End */}
                              {/* Inherited indicator info icon UI start */}

                              {/* {phrase.search_phrase_status === 'INHERITED' && ( */}
                              {/*   <Box> */}
                              {/*     <LightTooltip */}
                              {/*       placement="bottom" */}
                              {/*       arrow */}
                              {/*       title={t('Inherited Phrase')} */}
                              {/*     > */}
                              {/*       <img src={infoCircleIcon} width={20} /> */}
                              {/*     </LightTooltip> */}
                              {/*   </Box> */}
                              {/* )} */}
                              {/* Inherited indicator info icon UI End */}
                            </Stack>
                          </Box>

                          <Box
                            className="webkit-fill-available moz-available fill-available"
                            m={'5px !important'}
                            width={'100%'}
                            borderTop={
                              phrase.search_phrase_status === 'PAGE_CHANGED' ||
                              phrase.search_phrase_status === 'FOUND'
                                ? '1px solid var(--yellow-500) !important'
                                : phrase.search_phrase_status === 'NOT_FOUND'
                                  ? '1px solid var(--red-600) !important'
                                  : '1px solid var(--gray-200) !important'
                            }
                            py={1}
                            px={1}
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <span
                              style={{
                                fontSize: '13px',
                              }}
                            >
                              {formatDateToLocalTime(phrase.updated_at)}
                            </span>
                            {/* Edit and delete phrase action button UI start */}
                            <Box display="flex" gap={1}>
                              {/* To turn off phrase edit mode */}
                              {phrase.search_phrase_status === 'NOT_FOUND' ? (
                                <></>
                              ) : isPhraseEditMode?.isEditMode &&
                                editPhraseModeId === phrase.id ? ( // When user clicked on edit icon then to show clicked icon
                                <img
                                  src={filledEditIcon}
                                  alt="Edit"
                                  style={{
                                    pointerEvents:
                                      projectStatus ===
                                        'COLLATION_IN_PROGRESS' ||
                                      projectStatus ===
                                        'PREPARING_FOR_COLLATION' ||
                                      projectStatus ===
                                        'INHERITING_COLLATIONS' ||
                                      !isProgressComplete ||
                                      isManualCollationInProgress
                                        ? 'none'
                                        : 'auto',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    hideSelection()
                                    setAddIconId(null)
                                    updateHash(
                                      phrase,
                                      queryParameters.get('area_id'),
                                      phrase.id,
                                    )
                                    setSelectedHighlightId(phrase.id)
                                    setEditPhraseModeId(null)
                                    highlightAllStates[item.id]
                                      ? setSelectedHighlight(sourcePhrases)
                                      : setSelectedHighlight([phrase])
                                    setCollationName('')
                                    setIsPhraseEditMode({
                                      isEditMode: false,
                                      areaId: null,
                                      phraseId: null,
                                      phrases: [],
                                    })
                                  }}
                                />
                              ) : (
                                // To turn on phrase edit mode
                                <img
                                  src={editIcon}
                                  alt="Edit"
                                  style={{
                                    pointerEvents:
                                      projectStatus ===
                                        'COLLATION_IN_PROGRESS' ||
                                      projectStatus ===
                                        'PREPARING_FOR_COLLATION' ||
                                      projectStatus ===
                                        'INHERITING_COLLATIONS' ||
                                      !isProgressComplete ||
                                      isManualCollationInProgress
                                        ? 'none'
                                        : 'auto',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    if (
                                      localStorage.getItem(
                                        `update_dialog_from_source_phrases_projectID_${projectId}`,
                                      ) === 'true'
                                    ) {
                                      hideSelection()
                                      const selection: any =
                                        window.getSelection()
                                      selection.rangeCount > 0 &&
                                        selection.removeAllRanges()
                                      setAddIconId(null)
                                      setCollationName(item.name)
                                      setEditPhraseModeId(phrase.id)
                                      setSelectedHighlightId(null)
                                      setSelectedHighlight([])
                                      setIsPhraseEditMode({
                                        isEditMode: true,
                                        areaId: item.id,
                                        phraseId: phrase.id,
                                        phrases: sourcePhrases,
                                      })
                                    } else {
                                      handleOpenReplaceConfirmDialog(
                                        'update',
                                        item,
                                        phrase,
                                      )
                                      setIsPhraseEditMode({
                                        isEditMode: true,
                                        areaId: item.id,
                                        phraseId: phrase.id,
                                        phrases: sourcePhrases,
                                      })
                                      setSelectedHighlight([])
                                    }
                                  }}
                                />
                              )}
                              {/* Delete phrase button */}
                              <img
                                src={deleteIcon}
                                alt="Delete"
                                style={{
                                  pointerEvents:
                                    projectStatus === 'COLLATION_IN_PROGRESS' ||
                                    projectStatus ===
                                      'PREPARING_FOR_COLLATION' ||
                                    projectStatus === 'INHERITING_COLLATIONS' ||
                                    !isProgressComplete ||
                                    isManualCollationInProgress
                                      ? 'none'
                                      : 'auto',
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  hideSelection()
                                  localStorage.getItem(
                                    `delete_phrase_dialog_from_source_phrases_projectID_${projectId}`,
                                  ) === 'true'
                                    ? handleDeletePhrase(item.id, phrase.id)
                                    : handleOpenReplaceConfirmDialog(
                                        'delete_phrase',
                                        item,
                                        phrase,
                                      )
                                }}
                              />
                            </Box>
                          </Box>
                          {/* Edit and delete phrase action button UI end */}
                        </Stack>
                      )
                      // Show phrase text along with tooltip
                      return (
                        <React.Fragment key={phrase.id}>
                          {phraseContent}
                        </React.Fragment>
                      )
                    })
                  )}

                  {/* If higlight all being pressed then show disble All highlight button */}

                  {highlightAllStates[item.id] && sourcePhrases?.length > 1 && (
                    <TextButton
                      text={t('Stop Highlighting All')}
                      color={'var(--primary-600)'}
                      bgColor={'var(--gray-white)'}
                      style={{
                        width: '100%',
                        marginTop: '8px',
                        pointerEvents:
                          projectStatus === 'COLLATION_IN_PROGRESS' ||
                          projectStatus === 'PREPARING_FOR_COLLATION' ||
                          projectStatus === 'INHERITING_COLLATIONS' ||
                          !isProgressComplete ||
                          isManualCollationInProgress
                            ? 'none'
                            : 'auto',
                        border: '1px solid var(--primary-600)',
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        hideSelection()
                        handleChange(`searchPhrasePanel${item.id}`)
                        // setSelectedHighlight(item)
                        setHighlightAllStates((prevState) => ({
                          ...prevState,
                          [item.id]: false,
                        }))
                      }}
                    />
                  )}
                  {/* If source phrase source_phrases_finalized is false then only show Finalize Source Phrase/Phrases button */}

                  {sourcePhrases?.length > 0 &&
                    !item.source_phrases_finalized && (
                      <TextButton
                        text={
                          sourcePhrases?.length > 1
                            ? t('Finalize Source Phrases')
                            : t('Finalize Source Phrase')
                        }
                        color={'var(--gray-white)'}
                        bgColor={'var(--primary-600)'}
                        style={{
                          width: '100%',
                          marginTop: '8px',
                          //  disable clickevent based on conditions below
                          pointerEvents:
                            projectStatus === 'COLLATION_IN_PROGRESS' ||
                            projectStatus === 'PREPARING_FOR_COLLATION' ||
                            projectStatus === 'INHERITING_COLLATIONS' ||
                            !isProgressComplete ||
                            isManualCollationInProgress
                              ? 'none'
                              : 'auto',
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          hideSelection()
                          handleFinalizePhrase(item)
                        }}
                      />
                    )}
                  {/* If project status is in Collation Verification then only show Finalize Verification button */}

                  {projectStatus === 'COLLATION_VERIFICATION' &&
                    !item.collations_verified && (
                      <>
                        <TextButton
                          text={t('Finalize Verification')}
                          color={'var(--gray-white)'}
                          bgColor={'var(--primary-600)'}
                          style={{
                            width: '100%',
                            marginTop: '8px',
                            pointerEvents:
                              projectStatus === 'COLLATION_IN_PROGRESS' ||
                              projectStatus === 'PREPARING_FOR_COLLATION' ||
                              projectStatus === 'INHERITING_COLLATIONS' ||
                              !isProgressComplete ||
                              isManualCollationInProgress
                                ? 'none'
                                : 'auto',
                          }}
                          onClick={(e) => {
                            openVerificationDialog(
                              e,
                              item.id,
                              item.treasure_count,
                            )
                          }}
                        />

                        <CancelActionConformationFormDialog
                          open={isVerificationDialogOpen}
                          onClose={handleCloseVerificationConfirmDialog}
                          message={`Are you sure you want to finalize verification? This action will reset all treasure counts.`}
                        ></CancelActionConformationFormDialog>
                        {/* <Dialog
                          open={isVerificationDialogOpen}
                          onClose={cancelVerification}
                          fullWidth
                          maxWidth="xs"
                          BackdropProps={{
                            style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' }, // Lighter backdrop
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="end"
                            padding="5px 15px 0 0"
                          >
                            <Icon
                              onClick={cancelVerification}
                              style={{ cursor: 'pointer' }}
                            >
                              ✕
                            </Icon>
                          </Box>
                          <Stack alignItems="center" spacing={2} padding="16px">
                            <DialogTitle>
                              {t('Confirm Verification')}
                            </DialogTitle>
                            <DialogContent>
                              {t(
                                'Are you sure you want to finalize verification? This action will reset all treasure counts.',
                              )}
                            </DialogContent>
                            <DialogActions
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '20px',
                              }}
                            >
                              <Button
                                onClick={cancelVerification}
                                sx={{
                                  padding: '8px 18px',
                                  backgroundColor: 'var(--gray-200)',
                                  color: 'var(--gray-800)',
                                  '&:hover': {
                                    backgroundColor: 'var(--gray-300)',
                                  },
                                }}
                              >
                                {t('Cancel')}
                              </Button>
                              <Button
                                onClick={confirmVerification}
                                sx={{
                                  padding: '8px 18px',
                                  backgroundColor: 'var(--primary-600)',
                                  color: '#fff',
                                  '&:hover': {
                                    backgroundColor: 'var(--primary-700)',
                                  },
                                }}
                              >
                                {t('OK')}
                              </Button>
                            </DialogActions>
                          </Stack>
                        </Dialog> */}
                      </>
                    )}
                </>
              ) : (
                <div>
                  <CircularProgress />
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      )}
      <CancelActionConformationFormDialog
        open={dialogState?.isOpen}
        onClose={handlCloseCancelDeleteConfirmDialog}
        from={'source_phrases'}
        type={dialogState?.type}
        message={
          dialogState?.type === 'delete_phrase'
            ? t(
                'Deleting source phrase will result in permanent loss of any collation results attached to this phrase. You need to finalize phrases in the area again. Are you sure?',
              )
            : dialogState?.type === 'update'
              ? t(
                  'Updating source phrase will result in permanent loss of any collation results attached to this phrase. You need to finalize phrases in the area again. Are you sure?',
                )
              : dialogState?.type === 'delete_area'
                ? dialogState?.msg
                : dialogState?.msg
        }
        projectId={projectId}
      ></CancelActionConformationFormDialog>
    </Stack>
  )
}

const ROIStatus = ({
  roiList,
  setRoiList,
  updateHash,
  selectedHighlightId,
  setSelectedHighlightId,
  setSelectedHighlight,
  handleGetAreaList,
  projectId,
  projectStatus,
  isNewRoiAdded,
  memoizedRoiList,
  handleGetROIs,
  isRoiLoading,
  isManualCollationInProgress,
  setCollationList,
  t,
  isProgressComplete,
}: any) => {
  const [editingRoiId, setEditingRoiId] = useState(null)
  const [tempROIName, setTempROIName] = useState('')

  // Deletes an ROI and fetches updated list
  const handleDeleteROI = (id) => {
    deleteROI(projectId, id)
      .then((res) => {
        if (res.status === 200) {
          handleGetROIs(projectId)
        }
      })
      .catch((error) => {
        if (error?.response?.data?.detail) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  // Retries processing for a failed ROI and fetches updated list
  const handleRetryROI = (id) => {
    retryROI(projectId, id)
      .then((res) => {
        if (res.status === 200) {
          handleGetROIs(projectId)
        }
      })
      .catch((error) => {
        handleGetROIs(projectId)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  // Handles clicking an ROI, setting it as selected
  const handleClickROI = (highlight) => {
    if (highlight.id === selectedHighlightId) {
      document.location.hash = ''
      setSelectedHighlightId(null)
      setSelectedHighlight([])
      setCollationList(null)
    } else {
      const modifiedHighlights = {
        ...highlight,
        position: {
          boundingRect: { ...highlight.position },
          rects: [{ ...highlight.position }],
          pageNumber: highlight.page,
        },
        isRoi: true,
      }

      updateHash(modifiedHighlights)
      setSelectedHighlightId(modifiedHighlights.id)
      setSelectedHighlight([modifiedHighlights])
      setCollationList(null)
    }
  }

  // Updates the name for an ROI and fetches updated list
  const handleEditROIName = (newName, item) => {
    updateROI(projectId, { area: newName }, item.id)
      .then((res) => {
        if (res.status === 200) {
          handleGetROIs(projectId)
          enqueueSnackbar(t('RoI name successfully changed'), {
            variant: 'success',
            transitionDuration: { enter: 300, exit: 400 },
            autoHideDuration: 2,
            persist: true,
          })
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  return (
    <>
      {isRoiLoading ? (
        <div
          style={{
            paddingTop: '2rem',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Stack>
          {roiList?.length > 0 &&
            roiList?.map((highlight, index) => (
              // eslint-disable-next-line react/jsx-key
              <Box key={index}>
                <Stack
                  key={index}
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgcolor={
                    selectedHighlightId === highlight.id
                      ? 'var(--gray-200)'
                      : '#fff'
                  }
                  onClick={() => {
                    handleClickROI(highlight)
                  }}
                  style={{
                    textAlign: 'left',
                    fontSize: '14px',
                    border: '1px solid var(--gray-200)',
                    borderRadius: '4px',
                    boxShadow: 'none',
                    margin: '4px 0',
                  }}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    py={2}
                    px={1}
                  >
                    {editingRoiId === highlight.id ? (
                      <TextField
                        autoFocus
                        id="name"
                        type="text"
                        fullWidth
                        margin="none"
                        variant="standard"
                        style={{
                          //  disable clickevent based on conditions below
                          pointerEvents:
                            projectStatus === 'COLLATION_IN_PROGRESS' ||
                            projectStatus === 'PREPARING_FOR_COLLATION' ||
                            projectStatus === 'INHERITING_COLLATIONS' ||
                            !isProgressComplete ||
                            isManualCollationInProgress
                              ? 'none'
                              : 'auto',
                        }}
                        defaultValue={highlight?.area}
                        placeholder={t('Enter Collation Name')}
                        onChange={(e) => {
                          // e.target.value.trim()
                          setTempROIName(e.target.value)
                        }}
                        sx={{
                          '& .MuiInput-underline': {
                            color: 'var(--gray-600) !important',
                          },
                          '& .MuiInput-root': {
                            height: '20px !important',
                            padding: '0  0 5px 0 !important',
                          },
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleEditROIName(tempROIName, highlight) // Save changes
                            setEditingRoiId(null) // Exit edit mode
                            setTempROIName('') // Clear temporary name
                          }
                        }}
                      />
                    ) : (
                      <Tooltip title={highlight?.area}>
                        <span
                          style={{
                            textOverflow: 'ellipsis',
                            textWrap: 'nowrap',
                            overflow: 'hidden',
                            fontSize: '1rem',
                            color:
                              highlight.status === 'FAIL_RETRY'
                                ? 'var(--red-600)'
                                : '#000',
                          }}
                        >
                          {highlight?.area ? highlight?.area : '-'}
                        </span>
                      </Tooltip>
                    )}
                    <Box display="flex" gap={1} mr={2}>
                      {editingRoiId !== highlight.id &&
                        highlight.status === 'FAIL_RETRY' && (
                          <img
                            src={restartIcon}
                            alt="Restart"
                            style={{
                              pointerEvents:
                                projectStatus === 'COLLATION_IN_PROGRESS' ||
                                projectStatus === 'PREPARING_FOR_COLLATION' ||
                                projectStatus === 'INHERITING_COLLATIONS' ||
                                !isProgressComplete ||
                                isManualCollationInProgress
                                  ? 'none'
                                  : 'auto',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleRetryROI(highlight.id)
                            }}
                          />
                        )}
                      {highlight.status !== 'FAIL' &&
                        (editingRoiId === highlight.id ? (
                          <span
                            style={{
                              display: 'flex',
                              margin: '0 7px',
                              gap: '5px',
                            }}
                          >
                            <img
                              src={checkIcon}
                              alt="confirm"
                              style={{
                                pointerEvents:
                                  projectStatus === 'COLLATION_IN_PROGRESS' ||
                                  projectStatus === 'PREPARING_FOR_COLLATION' ||
                                  projectStatus === 'INHERITING_COLLATIONS' ||
                                  !isProgressComplete ||
                                  isManualCollationInProgress
                                    ? 'none'
                                    : 'auto',
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                handleEditROIName(tempROIName, highlight) // Save changes
                                setEditingRoiId(null) // Exit edit mode
                                setTempROIName('') // Clear temporary name
                              }}
                            />
                            <img
                              src={closeIcon}
                              alt="cancel"
                              style={{
                                pointerEvents:
                                  projectStatus === 'COLLATION_IN_PROGRESS' ||
                                  projectStatus === 'PREPARING_FOR_COLLATION' ||
                                  projectStatus === 'INHERITING_COLLATIONS' ||
                                  !isProgressComplete ||
                                  isManualCollationInProgress
                                    ? 'none'
                                    : 'auto',
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                                setEditingRoiId(null)
                                setTempROIName('') // Initialize with current name
                              }}
                            />
                          </span>
                        ) : (
                          <img
                            src={editIcon}
                            alt="Edit"
                            style={{
                              pointerEvents:
                                projectStatus === 'COLLATION_IN_PROGRESS' ||
                                projectStatus === 'PREPARING_FOR_COLLATION' ||
                                projectStatus === 'INHERITING_COLLATIONS' ||
                                !isProgressComplete ||
                                isManualCollationInProgress
                                  ? 'none'
                                  : 'auto',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              setEditingRoiId(highlight.id)
                              setTempROIName(highlight.area) // Initialize with current name
                            }}
                          />
                        ))}
                      <img
                        src={circleCloseIcon}
                        alt="Close"
                        style={{
                          //  disable clickevent based on conditions below
                          pointerEvents:
                            projectStatus === 'COLLATION_IN_PROGRESS' ||
                            projectStatus === 'PREPARING_FOR_COLLATION' ||
                            projectStatus === 'INHERITING_COLLATIONS' ||
                            !isProgressComplete ||
                            isManualCollationInProgress
                              ? 'none'
                              : 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteROI(highlight.id)
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    className="webkit-fill-available moz-available fill-available"
                    m={'0 !important'}
                    py={1}
                    px={1}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                  >
                    <span>
                      {t('Page')}: {highlight.page}
                    </span>
                    {highlight.status === 'FAIL_RETRY' && (
                      <span
                        style={{
                          color: 'var(--red-600)',
                          background: 'var(--red-50)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '10px',
                          padding: '5px',
                          borderRadius: '4px',
                        }}
                      >
                        {t('Failed')}
                        <Tooltip
                          sx={{
                            '& .MuiTooltip-tooltip': {
                              backgroundColor: 'var(--red-50) !important',
                            },
                          }}
                          title={t('Please Retry')}
                        >
                          <img src={infoIcon} />
                        </Tooltip>
                      </span>
                    )}
                    {highlight.status === 'PROCESSING' && (
                      <span
                        style={{
                          color: 'var(--gray-600)',
                          background: 'var(--gray-50)',
                          marginRight: '5px',
                          padding: '5px',
                          borderRadius: '4px',
                        }}
                      >
                        {t('Processing')}
                      </span>
                    )}
                    {highlight.status === 'IN_QUEUE' && (
                      <span
                        style={{
                          color: 'var(--gray-600)',
                          background: 'var(--gray-50)',
                          marginRight: '5px',
                          padding: '5px',
                          borderRadius: '4px',
                        }}
                      >
                        {t('In Queue')}
                      </span>
                    )}
                    {highlight.status === 'FAIL' && (
                      <span
                        style={{
                          color: 'var(--red-600)',
                          background: 'var(--red-50)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '10px',
                          padding: '5px',
                          borderRadius: '4px',
                        }}
                      >
                        {t('Failed')}
                        <Tooltip
                          sx={{
                            '& .MuiTooltip-tooltip': {
                              backgroundColor: 'var(--red-50) !important',
                            },
                          }}
                          title={t('Please delete this RoI and re-draw')}
                        >
                          <img src={infoIcon} />
                        </Tooltip>
                      </span>
                    )}
                  </Box>
                </Stack>
              </Box>
            ))}
        </Stack>
      )}
    </>
  )
}
const TargetDocument = ({
  targetFiles,
  setShowTargetPdfAndCollation,
  location,
  navigate,
  queryParameters,
  setTargetId,
  handleGetCollation,
  setTargetTitle,
  isTargetDataLoading,
  setCollationList,
  projectStatus,
  isManualCollationInProgress,
  setSelectedCollation,
  t,
  basePath,
  isProgressComplete,
}: any) => (
  <>
    {isTargetDataLoading ? (
      <div>
        <CircularProgress />
      </div>
    ) : (
      <Stack
        style={{
          gap: '5px',
        }}
      >
        {targetFiles?.map((item, index) => (
          // eslint-disable-next-line react/jsx-key
          <Box
            className={'targetList'}
            key={index}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mx={1}
            gap={2}
            style={{
              textWrap: 'nowrap',
              fontSize: '12px',
            }}
          >
            <Typography
              textOverflow={'ellipsis'}
              fontSize={'14px'}
              style={{
                textWrap: 'nowrap',
                overflowX: 'hidden',
                color: 'var(--primary-600',
                pointerEvents:
                  projectStatus === 'COLLATION_IN_PROGRESS' ||
                  projectStatus === 'PREPARING_FOR_COLLATION' ||
                  projectStatus === 'INHERITING_COLLATIONS' ||
                  !isProgressComplete ||
                  isManualCollationInProgress
                    ? 'none'
                    : 'auto',
                cursor: 'pointer',
                textDecoration:
                  queryParameters.get('target_id') &&
                  +queryParameters.get('target_id') === item.id
                    ? 'underline'
                    : 'none',
                fontWeight:
                  queryParameters.get('target_id') &&
                  +queryParameters.get('target_id') === item.id
                    ? 600
                    : 500,
              }}
              onClick={() => {
                setSelectedCollation([])
                setCollationList(null)
                // Navigate project with target id, phrase id and area id to render respective phrase and expand area
                // Also get Collation list respective to phraseId
                if (
                  queryParameters.get('phrase_id') &&
                  queryParameters.get('area_id')
                ) {
                  navigate(
                    `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${item.id}&area_id=${queryParameters.get('area_id')}&phrase_id=${queryParameters.get('phrase_id')}`,
                    { state: { location }, replace: true },
                  )
                  handleGetCollation(queryParameters.get('phrase_id'), item.id)
                }
                // Navigate project with target id, area id to expand respective area
                // Also set Collation list to null
                else if (
                  !queryParameters.get('phrase_id') &&
                  queryParameters.get('area_id')
                ) {
                  navigate(
                    `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${item.id}&area_id=${queryParameters.get('area_id')}`,
                    { state: { location }, replace: true },
                  )
                  setCollationList(null)
                }
                // Navigate project with target id to render respective target doc
                // Also set Collation list to null
                else {
                  navigate(
                    `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${item.id}`,
                    { state: { location }, replace: true },
                  )
                  setCollationList(null)
                }
                setTargetTitle(item.name)
                setTargetId(item.id)
                setShowTargetPdfAndCollation(true)
              }}
            >
              {item.name}
            </Typography>
            <Typography fontSize={'14px'}>
              {t('Best Rank:')} {''}
              {item.best_rank ? item.best_rank : '--'}
            </Typography>
            {/* <Typography>{item.rank}</Typography> */}
          </Box>
        ))}
      </Stack>
    )}
  </>
)

function AreaInfoMenu({ anchorElArea, openAreaInfo, handleCloseAreaInfo, t }) {
  // Prevent the menu from closing when clicking inside
  const handleMenuClick = (event) => {
    event.stopPropagation()
  }

  return (
    <Menu
      anchorEl={anchorElArea}
      id="area-info-menu"
      open={Boolean(openAreaInfo)}
      onClose={handleCloseAreaInfo}
      onClick={handleMenuClick} // Changed to handleMenuClick to stop propagation
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          boxShadow: 'none',
          mt: 3.5,
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 8,
            width: 10,
            height: 10,
            bgcolor: 'var(--red-50)',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      sx={{
        '& .MuiMenu-list': {
          background: 'var(--red-50)',
          width: '300px',
        },
      }}
    >
      <MenuItem
        disableRipple
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          cursor: 'text',
          background: 'var(--red-50)',
          whiteSpace: 'normal',
        }}
      >
        <Typography fontWeight={500}>{t('Urgent action required')}</Typography>
        <Typography fontWeight={400}>
          {t(
            'Please delete this area as it lacks source phrases, preventing the project from moving to completed status.',
          )}
        </Typography>
      </MenuItem>
    </Menu>
  )
}
